import type { Confirms } from '~/types/confirm'

export const useConfirm = () => {
  const confirms = useState<Confirms[]>('confirms', () => [])

  const confirm = (c: Confirms) => {
    confirms.value.push(c)
  }

  const close = () => {
    console.log(confirms.value)
    confirms.value.pop()
  }

  return { confirms, confirm, close }
}
