import '../assets/index.css';var $t = Object.defineProperty;
var Vt = (s, e, t) => e in s ? $t(s, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[e] = t;
var c = (s, e, t) => Vt(s, typeof e != "symbol" ? e + "" : e, t);
import { inject as rt, ref as b, onMounted as ce, onUnmounted as at, computed as h, toValue as ge, nextTick as le, getCurrentInstance as Ht, watch as G, provide as Bt, useId as Nt, onBeforeUnmount as lt, createVNode as ee, render as Ie, defineComponent as be, defineAsyncComponent as H, reactive as Ft, openBlock as A, createElementBlock as U, withModifiers as Z, withKeys as jt, unref as O, createCommentVNode as B, createElementVNode as V, normalizeClass as ue, withDirectives as ut, vShow as ct, createBlock as N, Teleport as qt, Transition as dt, withCtx as q, mergeProps as te, renderSlot as R, useAttrs as Ut, useSlots as Wt, createTextVNode as J, toDisplayString as X, Fragment as Ze, renderList as Rt, resolveDynamicComponent as _t, normalizeStyle as Kt } from "vue";
function Pe(s, e) {
  const t = rt(s, e);
  if (!t)
    throw new TypeError(`[maz-ui](injectStrict) Could not resolve ${s.toString()}`);
  return t;
}
function Wn() {
  return Pe("aos");
}
function Yt(s) {
  const e = {};
  for (const [t, n] of Object.entries(s))
    e[t] = Number.parseInt(n, 10);
  return e;
}
function F() {
  return typeof document < "u";
}
function Zt(s = {}) {
  const {
    internalWindow: e = F() ? window : void 0,
    initialWidth: t = Number.POSITIVE_INFINITY,
    initialHeight: n = Number.POSITIVE_INFINITY,
    includeScrollbar: o = !0
  } = s, i = b(t), r = b(n);
  function a() {
    e && (o ? (i.value = e.innerWidth, r.value = e.innerHeight) : (i.value = e.document.documentElement.clientWidth, r.value = e.document.documentElement.clientHeight));
  }
  return a(), ce(() => {
    e && window.addEventListener("resize", a, { passive: !0 });
  }), at(() => {
    e && window.removeEventListener("resize", a);
  }), { width: i, height: r };
}
function Rn({
  initialWidth: s = 0,
  initialHeight: e,
  includeScrollbar: t,
  internalWindow: n,
  listenOrientation: o,
  breakpoints: i,
  mediumBreakPoint: r = "md",
  largeBreakPoint: a = "lg"
}) {
  const { width: l } = Zt({
    initialWidth: s,
    // (SSR) mobile first
    initialHeight: e,
    includeScrollbar: t,
    internalWindow: n,
    listenOrientation: o
  }), u = Yt(i), y = h(() => l.value >= u[a]), T = h(
    () => l.value >= u[r] && l.value < u[a]
  ), w = h(() => l.value >= 0 && l.value < u[r]);
  return {
    width: l,
    numericBreakpoints: u,
    isSmallScreen: w,
    isLargeScreen: y,
    isMediumScreen: T,
    breakpoints: i
  };
}
function _n() {
  return Pe("dialog");
}
function mt(s, e, t) {
  const n = {
    maxAttempts: 20,
    interval: 100,
    ...t
  };
  let o = 0;
  function i() {
    const r = s();
    r != null ? n.expectedValue !== void 0 && r !== n.expectedValue ? o < n.maxAttempts ? (o++, setTimeout(i, n.interval)) : console.warn(n.errorMessage || `[maz-ui](checkAvailability) Nothing found after ${n.maxAttempts} attempts for ref ${r}`) : e(r) : o < n.maxAttempts ? (o++, setTimeout(i, n.interval)) : console.warn(n.errorMessage || `[maz-ui](checkAvailability) Nothing found or expected value after ${n.maxAttempts} attempts for ref ${r}`);
  }
  i();
}
function ft(s) {
  const e = ge(s);
  return Array.isArray(e) ? Object.freeze([...e]) : typeof e == "object" && e !== null ? Object.freeze({ ...e }) : e;
}
function Xe(s) {
  return s == null || typeof s == "string" || typeof s == "number" || typeof s == "boolean" || typeof s == "symbol" || typeof s == "bigint";
}
function Xt(s, e) {
  if (s.length !== e.length)
    return !1;
  for (const [t, n] of s.entries())
    if (!we(n, e[t]))
      return !1;
  return !0;
}
function Gt(s, e) {
  const t = Object.keys(s), n = Object.keys(e);
  if (t.length !== n.length)
    return !1;
  for (const o of t)
    if (!n.includes(o) || !we(s[o], e[o]))
      return !1;
  return !0;
}
function we(s, e) {
  return Xe(s) || Xe(e) ? s === e : s instanceof Date && e instanceof Date ? s.getTime() === e.getTime() : typeof s != typeof e || Array.isArray(s) !== Array.isArray(e) ? !1 : Array.isArray(s) && Array.isArray(e) ? Xt(s, e) : typeof s == "object" && typeof e == "object" ? Gt(s, e) : !1;
}
function Qt(s, e, t) {
  const n = {};
  return function(...o) {
    n[s] || (n[s] = { timer: null, promise: null });
    const i = n[s];
    return i.timer && clearTimeout(i.timer), i.promise = new Promise((r, a) => {
      i.timer = setTimeout(async () => {
        try {
          r(await e(...o));
        } catch (l) {
          a(l);
        } finally {
          delete n[s];
        }
      }, t);
    }), i.promise;
  };
}
function Jt(s, e, t) {
  const n = {};
  return async (...o) => {
    const i = Date.now();
    return n[s] || (n[s] = { promise: null, lastCall: 0, lastArgs: [] }), i - n[s].lastCall >= t ? (n[s].lastCall = i, e(...o)) : (n[s].lastArgs = o, n[s].promise || (n[s].promise = new Promise((r) => {
      setTimeout(
        async () => {
          n[s].lastCall = Date.now();
          const a = await e(...n[s].lastArgs);
          n[s].promise = null, r(a);
        },
        t - (i - n[s].lastCall)
      );
    })), n[s].promise);
  };
}
const se = {
  mode: "lazy",
  scrollToErrorSelector: ".has-field-error",
  debounceTime: 300,
  throttleTime: 1e3
};
function ht(s, e) {
  return Object.keys(e).includes(s);
}
function Ge(s = se.scrollToErrorSelector) {
  const e = document.querySelector(s);
  e && e.scrollIntoView({ behavior: "smooth", block: "center" });
}
function es(s, e) {
  const t = {};
  for (const [n, o] of Object.entries(s)) {
    const i = o;
    t[n] = e[n].error && i[0] ? i[0].message : void 0;
  }
  return t;
}
function Re(s) {
  return s == null || s === "";
}
function ts({
  name: s,
  hasValidation: e,
  debouncedFields: t,
  throttledFields: n
}) {
  if (!e)
    return;
  const o = String(s);
  if (t != null && t[o] && (n != null && n[o]))
    throw new Error(`The field "${o}" cannot be both debounced and throttled`);
  return t != null && t[o] ? Qt(
    o,
    ye,
    typeof t[o] == "number" ? t[o] : se.debounceTime
  ) : n != null && n[o] ? Jt(
    o,
    ye,
    typeof n[o] == "number" ? n[o] : se.throttleTime
  ) : ye;
}
function vt({
  name: s,
  schema: e,
  initialValue: t,
  fieldState: n,
  options: o
}) {
  const i = e ? ht(s, e) : !1, r = ts({
    name: s,
    hasValidation: i,
    debouncedFields: o == null ? void 0 : o.debouncedFields,
    throttledFields: o == null ? void 0 : o.throttledFields
  });
  return {
    blurred: !1,
    dirty: !1,
    errors: [],
    error: !1,
    valid: !i,
    validating: !1,
    validated: !1,
    initialValue: ft(t),
    validateFunction: r,
    mode: i ? (o == null ? void 0 : o.mode) ?? (n == null ? void 0 : n.mode) ?? se.mode : void 0
  };
}
function ss({
  schema: s,
  payload: e,
  options: t
}) {
  const n = {};
  for (const o in s) {
    const i = o;
    n[i] = vt({
      name: i,
      schema: s,
      options: t,
      fieldState: n[i],
      initialValue: e == null ? void 0 : e[i]
    });
  }
  return n;
}
function ns({
  fieldsStates: s,
  payload: e,
  schema: t,
  options: n,
  updateMode: o = !1
}) {
  for (const i in t) {
    const r = i;
    s[r] = pt({
      name: r,
      fieldState: s[r],
      payload: e,
      schema: t,
      options: n,
      updateMode: o
    });
  }
}
function pt({
  name: s,
  fieldState: e,
  payload: t,
  schema: n,
  options: o,
  updateMode: i = !0
}) {
  const { initialValue: r, mode: a, ...l } = vt({
    name: s,
    schema: n,
    initialValue: o.defaultValue ?? t[s],
    fieldState: e,
    options: o
  }), u = i ? a ?? e.mode ?? se.mode : e.mode;
  return {
    ...e,
    initialValue: r,
    mode: u,
    ...e != null && e.mode && u !== e.mode ? l : {}
  };
}
function os(s) {
  const e = {};
  for (const [t, { errors: n }] of Object.entries(s))
    e[t] = n;
  return e;
}
function is(s) {
  return s instanceof HTMLInputElement || s instanceof HTMLSelectElement || s instanceof HTMLTextAreaElement ? [s] : s.querySelectorAll(
    "input, select, textarea"
  );
}
function rs({
  interactiveElements: s,
  onBlur: e,
  mode: t
}) {
  s.forEach((n) => {
    Ee(["eager", "blur", "progressive"], t) && n.addEventListener("blur", e);
  });
}
function as({
  interactiveElements: s,
  onBlur: e
}) {
  s.forEach((t) => {
    t.removeEventListener("blur", e);
  });
}
async function ls(s, e, t) {
  const n = await hs(e), i = await (await bt("safeParseAsync"))(n.entries[s], t ?? "");
  return {
    result: i,
    isValid: i.success
  };
}
async function ye({
  name: s,
  fieldState: e,
  schema: t,
  payload: n,
  setError: o = !0,
  setErrorIfInvalidAndNotEmpty: i = !1
}) {
  if (await le(), e.validating = !0, !t[s]) {
    e.valid = !0, e.validating = !1, e.validated = !0, e.errors = [], e.error = !1;
    return;
  }
  const { result: r, isValid: a } = await ls(s, t, n[s]);
  e.valid = a, (o || i && !a && !Re(n[s])) && (e.error = !a), e.errors = r.issues ?? [], e.validating = !1, e.validated = !0;
}
function gt({
  name: s,
  fieldState: e,
  payload: t,
  schema: n
}) {
  var i;
  const o = {
    name: s,
    fieldState: e,
    payload: t,
    schema: n,
    setError: e.mode === "progressive" ? e.valid || e.blurred : !0
  };
  return (i = e.validateFunction) == null ? void 0 : i.call(e, o);
}
function us({
  fieldsStates: s,
  payload: e,
  showErrors: t = !0,
  schema: n
}) {
  return Promise.all(
    Object.keys(s).map(
      (o) => ye({
        name: o,
        setError: t,
        fieldState: s[o],
        payload: e,
        schema: n,
        setErrorIfInvalidAndNotEmpty: s[o].mode === "lazy"
      })
    )
  );
}
function yt({
  eventName: s,
  fieldState: e,
  isSubmitted: t
}) {
  const { dirty: n, blurred: o, mode: i, valid: r } = e;
  return s === "blur" && (Ee(["lazy", "aggressive"], i) || r) || s === "input" && i === "blur" || !i ? !1 : t || i === "eager" && o || i === "blur" && o || i === "aggressive" && n || i === "lazy" && n || i === "progressive";
}
function cs({
  name: s,
  force: e = !1,
  payload: t,
  fieldState: n,
  schema: o,
  isSubmitted: i
}) {
  const r = t[s], a = !Re(r) && !we(r, n.initialValue);
  if (n.dirty = a, n.blurred = n.blurred || (n.mode === "eager" ? a : !0), !!(e || yt({ eventName: "blur", fieldState: n, isSubmitted: i })))
    return gt({
      name: s,
      fieldState: n,
      schema: o,
      payload: t
    });
}
function ds({
  name: s,
  payload: e,
  fieldState: t,
  schema: n,
  isSubmitted: o,
  forceValidation: i = !1
}) {
  const r = e[s];
  t.validated = !1;
  const a = !Re(r) && !we(r, t.initialValue);
  if (t.dirty = a, !!(i || yt({ eventName: "input", fieldState: t, isSubmitted: o })))
    return gt({
      name: s,
      fieldState: t,
      schema: n,
      payload: e
    });
}
function _e(s) {
  const e = Ht();
  if (!e)
    throw new Error(`${s} must be called within setup()`);
  return e;
}
function ms(s, e) {
  var o;
  const n = ((o = _e(e).formContexts) == null ? void 0 : o.get(s)) ?? rt(s);
  if (!n)
    throw new Error("useFormField must be used within a form (useFormValidator)");
  return n;
}
function fs({
  ref: s,
  fieldState: e,
  onBlur: t
}) {
  if (!(s || Ee(["aggressive", "lazy"], e.mode)))
    return {
      onBlur: t
    };
}
const Ne = {};
async function bt(s) {
  if (Ne[s])
    return Ne[s];
  const e = await import("./index-BgyjI1q8.mjs");
  return Ne[s] = e[s], e[s];
}
async function hs(s) {
  return (await bt("objectAsync"))(s);
}
function Ee(s, e) {
  return e ? s.includes(e) : !1;
}
function Kn(s, e) {
  const t = {
    formIdentifier: "main-form-validator",
    ...e
  }, {
    fieldsStates: n,
    payload: o,
    options: i,
    internalSchema: r,
    errorMessages: a,
    isSubmitted: l
  } = ms(t.formIdentifier, "useFormField"), u = ht(s, r.value) ? (e == null ? void 0 : e.mode) ?? i.mode : void 0;
  t.mode = u;
  const y = h(() => n.value[s]);
  if (n.value[s] = pt({
    name: s,
    fieldState: y.value,
    payload: o.value,
    schema: r.value,
    options: { ...i, ...t }
  }), t.defaultValue !== void 0 && !we(o.value[s], t.defaultValue)) {
    const f = t.defaultValue;
    o.value[s] = f, n.value[s].initialValue = ft(f);
  }
  u && ye({
    name: s,
    fieldState: y.value,
    payload: o.value,
    schema: r.value,
    setError: u === "aggressive",
    setErrorIfInvalidAndNotEmpty: u === "lazy"
  });
  function T() {
    cs({
      name: s,
      fieldState: y.value,
      payload: o.value,
      schema: r.value,
      isSubmitted: l.value
    });
  }
  const w = h(
    () => fs({
      ref: t.ref,
      onBlur: T,
      fieldState: y.value
    })
  );
  if (t.ref && Ee(["eager", "blur", "progressive"], u)) {
    let f = [];
    const k = (z) => {
      f = is(z), rs({
        interactiveElements: f,
        onBlur: T,
        mode: u
      });
    };
    ce(() => {
      const z = _e(`useFormField of ${s}`);
      mt(() => z.refs[t.ref], (m) => {
        const d = m instanceof HTMLElement ? m : m == null ? void 0 : m.$el;
        d && k(d);
      }, {
        errorMessage: `[maz-ui](useFormField) No element found for ref ${t.ref} for field ${s}`
      });
    }), at(() => {
      as({
        interactiveElements: f,
        onBlur: T
      });
    });
  }
  return {
    hasError: h(() => y.value.error),
    errors: h(() => y.value.errors),
    errorMessage: h(() => a.value[s]),
    isValid: h(() => y.value.valid),
    isDirty: h(() => y.value.dirty),
    isBlurred: h(() => y.value.blurred),
    isValidated: h(() => y.value.validated),
    isValidating: h(() => y.value.validating),
    mode: h(() => y.value.mode),
    value: h({
      get: () => o.value[s],
      set: (f) => o.value[s] = f
    }),
    validationEvents: w,
    onBlur: T
  };
}
function Yn({ schema: s, defaultValues: e, model: t, options: n }) {
  const o = _e("useFormValidator"), i = {
    mode: se.mode,
    scrollToError: se.scrollToErrorSelector,
    debouncedFields: null,
    throttledFields: null,
    identifier: "main-form-validator",
    ...n
  }, r = b(e), a = b({ ...r.value, ...t == null ? void 0 : t.value }), l = b(s), u = b(
    ss({
      schema: l.value,
      payload: a.value,
      options: i
    })
  ), y = b(!1), T = b(!1), w = h(() => Object.values(u.value).every(({ valid: L }) => L)), f = h(() => Object.values(u.value).some(({ dirty: L }) => L)), k = h(() => os(u.value)), z = h(() => es(k.value, u.value));
  t && G(
    a,
    (L) => {
      t.value = { ...r.value, ...L };
    },
    { deep: !0 }
  ), G(
    r,
    (L) => {
      a.value = { ...L, ...a.value };
    },
    { deep: !0 }
  ), G(
    l,
    (L) => {
      ns({
        schema: L,
        fieldsStates: u.value,
        payload: a.value,
        options: i
      }), m();
    },
    { deep: !0 }
  ), m();
  function m(L = i.mode === "aggressive") {
    return us({
      fieldsStates: u.value,
      payload: a.value,
      schema: l.value,
      showErrors: L
    });
  }
  const d = [];
  async function C(L) {
    await le();
    const P = G(
      () => a.value[L],
      () => {
        const j = u.value[L];
        ds({
          name: L,
          fieldState: j,
          payload: a.value,
          schema: l.value,
          isSubmitted: T.value,
          forceValidation: Ee(["aggressive", "lazy", "progressive"], j.mode)
        });
      },
      { deep: typeof l.value[L] == "object" }
    );
    d.push(P);
  }
  function p() {
    for (const L of d)
      L();
    for (const L of Object.keys(l.value))
      C(L);
  }
  function S(L, P) {
    return async (j) => {
      if (j == null || j.preventDefault(), !y.value) {
        T.value = !0, y.value = !0;
        try {
          await m(!0);
          const ne = typeof P == "string" ? P : i.scrollToError;
          let Q;
          return w.value ? Q = await L(a.value) : typeof ne != "boolean" && Ge(ne), y.value = !1, Q;
        } finally {
          y.value = !1;
        }
      }
    };
  }
  const D = {
    fieldsStates: u,
    payload: a,
    options: i,
    internalSchema: l,
    errorMessages: z,
    isSubmitted: T
  };
  return o.formContexts ?? (o.formContexts = /* @__PURE__ */ new Map()), o.formContexts.set(i.identifier, D), Bt(i.identifier, D), p(), {
    identifier: i.identifier,
    isDirty: f,
    isSubmitting: y,
    isSubmitted: T,
    isValid: w,
    errors: k,
    model: a,
    fieldsStates: u,
    validateForm: m,
    scrollToError: Ge,
    handleSubmit: S,
    errorMessages: z
  };
}
class vs {
  constructor(e, t) {
    c(this, "defaultOptions", {
      element: void 0,
      timeout: 60 * 1e3 * 5,
      // 5 minutes
      once: !1,
      immediate: !0
    });
    c(this, "options");
    c(this, "timeoutHandler");
    c(this, "isIdle", !1);
    c(this, "isDestroy", !1);
    c(this, "startTime", 0);
    c(this, "remainingTime", 0);
    c(this, "lastClientX", -1);
    c(this, "lastClientY", -1);
    c(this, "eventNames", [
      "DOMMouseScroll",
      "mousedown",
      "mousemove",
      "mousewheel",
      "MSPointerDown",
      "MSPointerMove",
      "keydown",
      "touchmove",
      "touchstart",
      "wheel",
      "click"
    ]);
    c(this, "handleEvent", (e) => {
      try {
        if (this.remainingTime > 0)
          return;
        if (e.type === "mousemove") {
          const { clientX: t, clientY: n } = e;
          if (t === void 0 && n === void 0 || t === this.lastClientX && n === this.lastClientY)
            return;
          this.lastClientX = t, this.lastClientY = n;
        }
        this.resetTimeout(), this.callback({ isIdle: this.isIdle, eventType: e.type, instance: this });
      } catch (t) {
        throw new Error(`[IdleTimeout](handleEvent) ${t}`);
      }
    });
    this.callback = e, this.options = {
      ...this.defaultOptions,
      ...t
    }, F() && this.start();
  }
  get element() {
    return this.options.element ?? document.body;
  }
  start() {
    if (!F()) {
      console.warn("[IdleTimeout](start) you should run this method on client side");
      return;
    }
    for (const e of this.eventNames)
      this.element.addEventListener(e, this.handleEvent);
    this.resetTimeout(), this.options.immediate && this.callback({ isIdle: !1, instance: this });
  }
  pause() {
    const e = this.startTime + this.options.timeout - Date.now();
    e <= 0 || (this.remainingTime = e, this.timeoutHandler && (clearTimeout(this.timeoutHandler), this.timeoutHandler = void 0));
  }
  resume() {
    this.remainingTime <= 0 || (this.resetTimeout(), this.callback({ isIdle: this.isIdle, instance: this }), this.remainingTime = 0);
  }
  reset() {
    this.isDestroy = !1, this.isIdle = !1, this.remainingTime = 0, this.resetTimeout(), this.callback({ isIdle: this.isIdle, instance: this });
  }
  destroy() {
    if (!F()) {
      console.warn("[IdleTimeout](destroy) you should run this method on client side");
      return;
    }
    this.isDestroy = !0;
    for (const e of this.eventNames)
      this.element.removeEventListener(e, this.handleEvent);
    this.timeoutHandler && clearTimeout(this.timeoutHandler);
  }
  resetTimeout() {
    this.isIdle = !1, this.timeoutHandler && (clearTimeout(this.timeoutHandler), this.timeoutHandler = void 0), this.timeoutHandler = setTimeout(
      this.handleTimeout.bind(this),
      this.remainingTime || this.options.timeout
    ), this.startTime = Date.now();
  }
  handleTimeout() {
    this.isIdle = !0, this.callback({ isIdle: this.isIdle, instance: this }), this.options.once && this.destroy();
  }
  get destroyed() {
    return this.isDestroy;
  }
  get timeout() {
    return this.options.timeout;
  }
  set timeout(e) {
    this.options.timeout = e;
  }
  get idle() {
    return this.isIdle;
  }
  set idle(e) {
    e ? this.handleTimeout() : this.reset(), this.callback({ isIdle: this.isIdle, instance: this });
  }
}
function Zn({
  callback: s,
  options: e
}) {
  return new vs(s, e);
}
function Xn({
  componentName: s,
  providedId: e
}) {
  return h(() => e ?? `${s}-${Nt().replace(/:/g, "")}`);
}
const ps = [
  "af-ZA",
  "sq-AL",
  "ar-DZ",
  "ar-BH",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-TN",
  "ar-AE",
  "ar-YE",
  "hy-AM",
  "Cy-az-AZ",
  "Lt-az-AZ",
  "eu-ES",
  "be-BY",
  "bg-BG",
  "ca-ES",
  "zh-CN",
  "zh-HK",
  "zh-MO",
  "zh-SG",
  "zh-TW",
  "zh-CHS",
  "zh-CHT",
  "hr-HR",
  "cs-CZ",
  "da-DK",
  "div-MV",
  "nl-BE",
  "nl-NL",
  "en-AU",
  "en-BZ",
  "en-CA",
  "en-CB",
  "en-IE",
  "en-JM",
  "en-NZ",
  "en-PH",
  "en-ZA",
  "en-TT",
  "en-GB",
  "en-US",
  "en-ZW",
  "et-EE",
  "fo-FO",
  "fa-IR",
  "fi-FI",
  "fr-BE",
  "fr-CA",
  "fr-FR",
  "fr-LU",
  "fr-MC",
  "fr-CH",
  "gl-ES",
  "ka-GE",
  "de-AT",
  "de-DE",
  "de-LI",
  "de-LU",
  "de-CH",
  "el-GR",
  "gu-IN",
  "he-IL",
  "hi-IN",
  "hu-HU",
  "is-IS",
  "id-ID",
  "it-IT",
  "it-CH",
  "ja-JP",
  "kn-IN",
  "kk-KZ",
  "kok-IN",
  "ko-KR",
  "ky-KZ",
  "lv-LV",
  "lt-LT",
  "mk-MK",
  "ms-BN",
  "ms-MY",
  "mr-IN",
  "mn-MN",
  "nb-NO",
  "nn-NO",
  "pl-PL",
  "pt-BR",
  "pt-PT",
  "pa-IN",
  "ro-RO",
  "ru-RU",
  "sa-IN",
  "Cy-sr-SP",
  "Lt-sr-SP",
  "sk-SK",
  "sl-SI",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-DO",
  "es-EC",
  "es-SV",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PY",
  "es-PE",
  "es-PR",
  "es-ES",
  "es-UY",
  "es-VE",
  "sw-KE",
  "sv-FI",
  "sv-SE",
  "syr-SY",
  "ta-IN",
  "tt-RU",
  "te-IN",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "ur-PK",
  "Cy-uz-UZ",
  "Lt-uz-UZ",
  "vi-VN"
], gs = [
  "aa",
  "ab",
  "ae",
  "af",
  "ak",
  "am",
  "an",
  "ar",
  "as",
  "av",
  "ay",
  "az",
  "ba",
  "be",
  "bg",
  "bh",
  "bi",
  "bm",
  "bn",
  "bo",
  "br",
  "bs",
  "ca",
  "ce",
  "ch",
  "co",
  "cr",
  "cs",
  "cu",
  "cv",
  "cy",
  "da",
  "de",
  "dv",
  "dz",
  "ee",
  "el",
  "en",
  "eo",
  "es",
  "et",
  "eu",
  "fa",
  "ff",
  "fi",
  "fj",
  "fo",
  "fr",
  "fy",
  "ga",
  "gd",
  "gl",
  "gn",
  "gu",
  "gv",
  "ha",
  "he",
  "hi",
  "ho",
  "hr",
  "ht",
  "hu",
  "hy",
  "hz",
  "ia",
  "id",
  "ie",
  "ig",
  "ii",
  "ik",
  "io",
  "is",
  "it",
  "iu",
  "ja",
  "jv",
  "ka",
  "kg",
  "ki",
  "kj",
  "kk",
  "kl",
  "km",
  "kn",
  "ko",
  "kr",
  "ks",
  "ku",
  "kv",
  "kw",
  "ky",
  "la",
  "lb",
  "lg",
  "li",
  "ln",
  "lo",
  "lt",
  "lu",
  "lv",
  "mg",
  "mh",
  "mi",
  "mk",
  "ml",
  "mn",
  "mr",
  "ms",
  "mt",
  "my",
  "na",
  "nb",
  "nd",
  "ne",
  "ng",
  "nl",
  "nn",
  "no",
  "nr",
  "nv",
  "ny",
  "oc",
  "oj",
  "om",
  "or",
  "os",
  "pa",
  "pi",
  "pl",
  "ps",
  "pt",
  "qu",
  "rm",
  "rn",
  "ro",
  "ru",
  "rw",
  "sa",
  "sc",
  "sd",
  "se",
  "sg",
  "si",
  "sk",
  "sl",
  "sm",
  "sn",
  "so",
  "sq",
  "sr",
  "ss",
  "st",
  "su",
  "sv",
  "sw",
  "ta",
  "te",
  "tg",
  "th",
  "ti",
  "tk",
  "tl",
  "tn",
  "to",
  "tr",
  "ts",
  "tt",
  "tw",
  "ty",
  "ug",
  "uk",
  "ur",
  "uz",
  "ve",
  "vi",
  "vo",
  "wa",
  "wo",
  "xh",
  "yi",
  "yo",
  "za",
  "zh",
  "zu"
], ys = [...gs, ...ps];
let W;
function Qe(s, e) {
  return !s || (s == null ? void 0 : s.toLocaleLowerCase()) === e.toLocaleLowerCase();
}
function Gn(s) {
  function e(n, o) {
    return h(() => {
      const i = ge(o), r = ge(n);
      try {
        if (!i || !r)
          return r;
        (!W || i !== W.resolvedOptions().locale) && (W = new Intl.DisplayNames([i], { type: "language" }));
        const a = W.of(r);
        return !a || Qe(a, r) ? void 0 : a;
      } catch {
        return r;
      }
    });
  }
  function t(n) {
    return h(() => {
      const o = ge(n);
      return o ? ((!W || o !== W.resolvedOptions().locale) && (W = new Intl.DisplayNames([o], {
        type: "language"
      })), ys.map((i) => {
        try {
          const r = W == null ? void 0 : W.of(i);
          return !r || Qe(r, i) ? void 0 : {
            language: r,
            code: i
          };
        } catch {
          return;
        }
      }).filter(Boolean)) : [];
    });
  }
  return {
    getLanguageDisplayName: ({ code: n, locale: o }) => e(n, o || s),
    getAllLanguageDisplayNames: (n) => t(n || s)
  };
}
function wt(s) {
  const e = s.join(", ").match(/\b\w+\b/g);
  return e ? e.length : 0;
}
function bs(s, e = 150) {
  const t = wt([s]);
  return Math.ceil(t / e);
}
function Qn(s) {
  const e = h(
    () => {
      var r;
      return typeof s.velocity == "number" ? s.velocity : ((r = s.velocity) == null ? void 0 : r.value) ?? 150;
    }
  ), t = h(() => {
    var r;
    return typeof s.contentSelector == "string" ? s.contentSelector : (r = s.contentSelector) == null ? void 0 : r.value;
  }), n = h(() => {
    var r, a, l;
    if (typeof ((r = s.contentRef) == null ? void 0 : r.value) == "object")
      return (a = s.contentRef.value) == null ? void 0 : a.textContent;
    if (t.value && typeof document < "u") {
      const u = document.querySelector(t.value);
      if (u)
        return u.textContent;
    }
    return typeof s.content == "string" ? s.content : (l = s.content) == null ? void 0 : l.value;
  }), o = h(() => bs(n.value, e.value)), i = h(() => wt([n.value]));
  return {
    content: n,
    wordCount: i,
    velocity: e,
    duration: o
  };
}
const ws = {
  removeAccents: !0,
  caseSensitive: !1,
  replaceSpaces: !0,
  removeSpecialCharacters: !1,
  trim: !0,
  normalizeSpaces: !0,
  removeNumbers: !1,
  customNormalizationForms: ["NFC", "NFKD"]
};
function Je(s, e) {
  const t = { ...ws, ...e }, n = {
    À: "A",
    Á: "A",
    Â: "A",
    Ã: "A",
    Ä: "A",
    Å: "A",
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    ä: "a",
    å: "a",
    È: "E",
    É: "E",
    Ê: "E",
    Ë: "E",
    è: "e",
    é: "e",
    ê: "e",
    ë: "e",
    Î: "I",
    Ï: "I",
    í: "I",
    î: "i",
    ï: "i",
    Ô: "O",
    Õ: "O",
    Ö: "O",
    Ø: "O",
    ô: "o",
    õ: "o",
    ö: "o",
    ø: "o",
    Ù: "U",
    Ú: "U",
    Û: "U",
    Ü: "U",
    ù: "u",
    ú: "u",
    û: "u",
    ü: "u",
    Ç: "C",
    ç: "c",
    ÿ: "y",
    Ñ: "N",
    ñ: "n",
    ó: "o"
  };
  let o = s.toString();
  if (t.trim && (o = o.trim()), t.normalizeSpaces && (o = o.replaceAll(/\s+/g, " ")), t.replaceSpaces && (o = o.replaceAll(" ", "-")), t.removeNumbers && (o = o.replaceAll(/\d/g, "")), t.removeAccents && (o = o.replaceAll(/[ÀÁÂÃÄÅÇÈÉÊËÎÏÑÔÕÖØÙÚÛÜàáâãäåçèéêëíîïñóôõöøùúûüÿ]/g, (i) => n[i] || i), o = o.replaceAll(/[\u0300-\u036F]/g, "")), t.caseSensitive === !1 && (o = o.toLowerCase()), t.removeSpecialCharacters && (o = o.replaceAll(/[^\dA-Z-]/gi, "")), t.trim && (o = o.trim()), t.customNormalizationForms)
    for (const i of t.customNormalizationForms)
      o = o.normalize(i);
  return o;
}
function Es(s, e) {
  const t = s.length, n = e.length, o = [];
  for (let i = 0; i <= t; i++)
    o[i] = [i];
  for (let i = 0; i <= n; i++)
    o[0][i] = i;
  for (let i = 1; i <= t; i++)
    for (let r = 1; r <= n; r++) {
      const a = s[i - 1] === e[r - 1] ? 0 : 1;
      o[i][r] = Math.min(
        o[i - 1][r] + 1,
        o[i][r - 1] + 1,
        o[i - 1][r - 1] + a
      );
    }
  return o[t][n];
}
function Et(s, e) {
  const t = Es(s, e), n = Math.max(s.length, e.length);
  return 1 - t / n;
}
function Ts(s, e, t = 0.75) {
  return Et(s, e) >= t;
}
function Jn(s, e, t = 0.75) {
  const n = h(
    () => Je(typeof s == "string" ? s : s.value)
  ), o = h(
    () => Je(typeof e == "string" ? e : e.value)
  ), i = h(() => typeof t == "number" ? t : t.value), r = h(() => Et(n.value, o.value));
  return {
    isMatching: h(
      () => Ts(n.value, o.value, i.value)
    ),
    score: r
  };
}
const zs = {
  preventDefaultOnTouchMove: !1,
  preventDefaultOnMouseWheel: !1,
  threshold: 50,
  immediate: !1,
  triggerOnEnd: !1
};
class Ls {
  constructor(e) {
    c(this, "element");
    c(this, "xStart");
    c(this, "yStart");
    c(this, "xEnd");
    c(this, "yEnd");
    c(this, "xDiff");
    c(this, "yDiff");
    c(this, "onToucheStartCallback");
    c(this, "onToucheMoveCallback");
    c(this, "onToucheEndCallback");
    c(this, "onMouseWheelCallback");
    c(this, "start");
    c(this, "stop");
    c(this, "options");
    this.inputOption = e, this.options = { ...zs, ...e }, this.onToucheStartCallback = this.toucheStartHandler.bind(this), this.onToucheMoveCallback = this.handleTouchMove.bind(this), this.onToucheEndCallback = this.handleTouchEnd.bind(this), this.onMouseWheelCallback = this.handleMouseWheel.bind(this), this.start = this.startListening.bind(this), this.stop = this.stopListening.bind(this), this.options.element && this.setElement(this.options.element), this.options.immediate && this.start();
  }
  startListening() {
    this.setElement(this.options.element), this.element.addEventListener("touchstart", this.onToucheStartCallback, { passive: !0 }), this.element.addEventListener("touchmove", this.onToucheMoveCallback, { passive: !0 }), this.options.triggerOnEnd && this.element.addEventListener("touchend", this.onToucheEndCallback, { passive: !0 }), this.options.preventDefaultOnMouseWheel && this.element.addEventListener("mousewheel", this.onMouseWheelCallback, { passive: !1 });
  }
  stopListening() {
    this.element.removeEventListener("touchstart", this.onToucheStartCallback), this.element.removeEventListener("touchmove", this.onToucheMoveCallback), this.element.removeEventListener("touchend", this.onToucheEndCallback), this.options.preventDefaultOnMouseWheel && this.element.removeEventListener("mousewheel", this.onMouseWheelCallback);
  }
  setElement(e) {
    if (!e) {
      console.error(
        "[maz-ui][SwipeHandler](setElement) Element should be provided. Its can be a string selector or an HTMLElement"
      );
      return;
    }
    if (typeof e == "string") {
      const t = document.querySelector(e);
      if (!(t instanceof HTMLElement)) {
        console.error("[maz-ui][SwipeHandler](setElement) String selector for element is not found");
        return;
      }
      this.element = t;
    } else
      this.element = e;
  }
  handleMouseWheel(e) {
    e.preventDefault();
  }
  toucheStartHandler(e) {
    this.xStart = e.touches[0].clientX, this.yStart = e.touches[0].clientY, this.emitValuesChanged();
  }
  emitValuesChanged() {
    var e, t;
    (t = (e = this.options).onValuesChanged) == null || t.call(e, {
      xStart: this.xStart,
      yStart: this.yStart,
      xEnd: this.xEnd,
      yEnd: this.yEnd,
      xDiff: this.xDiff,
      yDiff: this.yDiff
    });
  }
  handleTouchMove(e) {
    this.options.preventDefaultOnTouchMove && e.cancelable && e.preventDefault(), this.xEnd = e.touches[0].clientX, this.yEnd = e.touches[0].clientY, !(!this.xStart || !this.yStart) && (this.xDiff = this.xStart - this.xEnd, this.yDiff = this.yStart - this.yEnd, this.emitValuesChanged(), this.options.triggerOnEnd || this.runCallbacks(e));
  }
  handleTouchEnd(e) {
    this.runCallbacks(e), this.emitValuesChanged();
  }
  runCallbacks(e) {
    var t, n, o, i, r, a, l, u;
    typeof this.xDiff != "number" || typeof this.yDiff != "number" || Math.abs(this.xDiff) < this.options.threshold && Math.abs(this.yDiff) < this.options.threshold || (Math.abs(this.xDiff) > Math.abs(this.yDiff) ? this.xDiff > 0 ? (n = (t = this.options).onLeft) == null || n.call(t, e) : (i = (o = this.options).onRight) == null || i.call(o, e) : this.yDiff > 0 ? (a = (r = this.options).onUp) == null || a.call(r, e) : (u = (l = this.options).onDown) == null || u.call(l, e), this.resetValues());
  }
  resetValues() {
    this.xStart = void 0, this.yStart = void 0, this.xEnd = void 0, this.yEnd = void 0, this.xDiff = void 0, this.yDiff = void 0, this.emitValuesChanged();
  }
}
function eo(s) {
  const e = b(), t = b(), n = b(), o = b(), i = b(), r = b(), a = h(() => ge(s.element)), l = new Ls({
    ...s,
    element: a.value,
    onValuesChanged(u) {
      e.value = u.xDiff, t.value = u.yDiff, n.value = u.xStart, o.value = u.xEnd, i.value = u.yStart, r.value = u.yEnd;
    }
  });
  return {
    xDiff: e,
    yDiff: t,
    xStart: n,
    xEnd: o,
    yStart: i,
    yEnd: r,
    start: () => {
      a.value && (l.options.element = a.value), l.start();
    },
    stop: l.stop
  };
}
const ks = {
  darkClass: "dark",
  lightClass: "light",
  storageThemeKey: "theme",
  storageThemeValueDark: "dark",
  storageThemeValueLight: "light",
  storageThemeValueSystem: "system",
  watchChanges: !0
}, Te = b("system"), Y = b("system");
function et() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
}
function ze({
  darkClass: s,
  lightClass: e,
  storageThemeKey: t,
  storageThemeValueDark: n,
  setLocalStorageValue: o = !0,
  setSelectedTheme: i = !0
}) {
  F() && (document.documentElement.classList.remove(e), document.documentElement.classList.add(s), Te.value = n, i && (Y.value = n), o && (localStorage[t] = n));
}
function Le({
  darkClass: s,
  lightClass: e,
  storageThemeKey: t,
  storageThemeValueLight: n,
  setLocalStorageValue: o = !0,
  setSelectedTheme: i = !0
}) {
  F() && (document.documentElement.classList.remove(s), document.documentElement.classList.add(e), Te.value = n, i && (Y.value = n), o && (localStorage[t] = n));
}
function Tt({
  setLocalStorageValue: s = !0,
  ...e
}) {
  if (F())
    return document.documentElement.classList.remove(e.darkClass), document.documentElement.classList.remove(e.lightClass), Te.value = e.storageThemeValueSystem, Y.value = e.storageThemeValueSystem, s && (localStorage[e.storageThemeKey] = e.storageThemeValueSystem), qe(e);
}
function zt(s) {
  if (!s.defaultTheme)
    return console.error("[maz-ui](useThemeHandler) No default theme set");
  if (!["light", "dark"].includes(s.defaultTheme))
    return console.error('[maz-ui](useThemeHandler) Default theme must be "light" or "dark"');
  switch (s.defaultTheme) {
    case "dark":
      return ze({
        ...s,
        setLocalStorageValue: !1,
        setSelectedTheme: !0
      });
    case "light":
      return Le({
        ...s,
        setLocalStorageValue: !1,
        setSelectedTheme: !0
      });
  }
}
function qe(s) {
  return F() ? !localStorage[s.storageThemeKey] && s.defaultTheme ? zt(s) : localStorage[s.storageThemeKey] === s.storageThemeValueDark || !localStorage[s.storageThemeKey] && et() || localStorage[s.storageThemeKey] === s.storageThemeValueSystem && et() ? ze({
    ...s,
    setLocalStorageValue: !1,
    setSelectedTheme: !1
  }) : Le({
    ...s,
    setLocalStorageValue: !1,
    setSelectedTheme: !1
  }) : void 0;
}
function Fe({
  theme: s,
  ...e
}) {
  return s === "system" ? Tt(e) : s === "dark" ? ze(e) : Le(e);
}
function Cs(s) {
  return Te.value === s.storageThemeValueDark ? Le(s) : ze(s);
}
function to(s) {
  const e = {
    ...ks,
    ...s
  };
  function t() {
    qe(e);
  }
  return ce(() => {
    localStorage[e.storageThemeKey] && (Y.value = localStorage[e.storageThemeKey]), e.watchChanges && window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", t);
  }), lt(() => {
    e.watchChanges && window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", t);
  }), G(Y, (n) => {
    switch (localStorage[e.storageThemeKey] = n, n) {
      case e.storageThemeValueDark: {
        ze(e);
        break;
      }
      case e.storageThemeValueLight: {
        Le(e);
        break;
      }
      case e.storageThemeValueSystem: {
        Tt(e);
        break;
      }
    }
  }), {
    autoSetTheme: () => qe(e),
    toggleTheme: () => Cs(e),
    setSystemTheme: () => Fe({ ...e, theme: "system" }),
    setDarkTheme: () => Fe({ ...e, theme: "dark" }),
    setLightTheme: () => Fe({ ...e, theme: "light" }),
    setDefaultTheme: () => zt(e),
    hasDarkTheme: h(() => Y.value === e.storageThemeValueDark),
    hasLightTheme: h(() => Y.value === e.storageThemeValueLight),
    hasSystemTheme: h(() => Y.value === e.storageThemeValueSystem),
    theme: Te,
    selectedTheme: Y
  };
}
function Is({ timeout: s = 1e3, callback: e, remainingTimeUpdate: t = 200, callbackOffsetTime: n = 0 }) {
  const o = b(s), i = b(s);
  let r;
  function a(w) {
    typeof w == "number" && (i.value = w), typeof w == "number" && (o.value = w), l();
  }
  function l() {
    r || (r = setInterval(() => {
      i.value -= t, i.value <= 0 && (T(), setTimeout(() => e == null ? void 0 : e(), n));
    }, t));
  }
  function u() {
    r && (clearInterval(r), r = void 0);
  }
  function y() {
    !r && i.value > 0 && l();
  }
  function T() {
    setTimeout(() => i.value = o.value, n * 2), u();
  }
  return {
    remainingTime: i,
    start: a,
    pause: u,
    resume: y,
    stop: T
  };
}
function so() {
  return Pe("toast");
}
class As {
  constructor(e, t) {
    c(this, "eventHandlerFunction");
    c(this, "event", "visibilitychange");
    c(this, "timeoutHandler");
    c(this, "options");
    c(this, "defaultOptions", {
      timeout: 5e3,
      once: !1,
      immediate: !0
    });
    c(this, "isVisible", !1);
    this.callback = e, this.options = {
      ...this.defaultOptions,
      ...t
    }, this.eventHandlerFunction = this.eventHandler.bind(this), F() && this.start();
  }
  start() {
    if (!F()) {
      console.warn("[UserVisibility](start) you should run this method on client side");
      return;
    }
    this.options.immediate && this.emitCallback(), this.addEventListener();
  }
  emitCallback() {
    this.isVisible = document.visibilityState === "visible", this.callback({ isVisible: this.isVisible }), this.options.once && this.destroy();
  }
  eventHandler() {
    document.visibilityState === "visible" && !this.isVisible ? (this.clearTimeout(), this.emitCallback()) : document.visibilityState === "hidden" && this.initTimeout();
  }
  clearTimeout() {
    this.timeoutHandler && (clearTimeout(this.timeoutHandler), this.timeoutHandler = void 0);
  }
  initTimeout() {
    this.clearTimeout(), this.timeoutHandler = setTimeout(this.emitCallback.bind(this), this.options.timeout);
  }
  addEventListener() {
    document.addEventListener(this.event, this.eventHandlerFunction);
  }
  removeEventListener() {
    document.removeEventListener(this.event, this.eventHandlerFunction);
  }
  destroy() {
    this.removeEventListener(), this.timeoutHandler && clearTimeout(this.timeoutHandler);
  }
}
function no({
  callback: s,
  options: e
}) {
  return new As(s, e);
}
function oo() {
  return Pe("wait");
}
const De = "__maz-click-outside__";
function Lt() {
  return document.ontouchstart === null ? "touchstart" : "click";
}
async function kt(s, e) {
  try {
    Ct(s);
    const t = e.instance, n = e.value, o = typeof n == "function";
    if (!o)
      throw new Error("[maz-ui](vClickOutside) the callback should be a function");
    await le(), s[De] = (r) => {
      if ((!s || r.target && !s.contains(r.target)) && n && o)
        return n.call(t, r);
    };
    const i = Lt();
    document.addEventListener(i, s[De], { passive: !0 });
  } catch (t) {
    console.error("[maz-ui](vClickOutside)", t);
  }
}
function Ct(s) {
  try {
    const e = Lt();
    document.removeEventListener(e, s[De], !1), delete s[De];
  } catch (e) {
    console.error("[maz-ui](vClickOutside)", e);
  }
}
function Ss(s, e) {
  try {
    if (e.value === e.oldValue)
      return;
    kt(s, e);
  } catch (t) {
    console.error("[maz-ui](vClickOutside)", t);
  }
}
const xs = {
  mounted: kt,
  updated: Ss,
  unmounted: Ct
}, Os = {
  install: (s) => {
    s.directive("click-outside", xs);
  }
};
function It(s, e, t) {
  var r, a;
  s.stopPropagation();
  const n = typeof t.value == "function" ? t.value : t.value.handler, o = typeof t.value == "object" ? t.value.exclude : void 0;
  let i = !1;
  if (o && o.length > 0) {
    for (const l of o)
      if (!i && s.target instanceof HTMLElement) {
        const u = (r = document.querySelector(l)) == null ? void 0 : r.getAttribute("id");
        i = (s.target.getAttribute("id") === u || ((a = document.querySelector(l)) == null ? void 0 : a.contains(s.target))) ?? !1;
      }
  }
  !e.contains(s.target) && !i && (n == null || n(s));
}
function At() {
  return document.ontouchstart === null ? "touchstart" : "click";
}
function Ds(s, e) {
  const t = At();
  document.removeEventListener(t, (n) => It(n, s, e));
}
function Ps(s, e) {
  if (typeof e.value != "function" && typeof e.value == "object" && typeof e.value.handler != "function") {
    console.error("[maz-ui](vClosable) v-closable directive requires a handler function");
    return;
  }
  const t = At();
  document.addEventListener(t, (n) => It(n, s, e));
}
const Ms = {
  mounted: Ps,
  unmounted: Ds
}, $s = {
  install: (s) => {
    s.directive("closable", Ms);
  }
}, Ue = {
  position: "top"
};
class St {
  constructor(e = {}) {
    c(this, "options");
    this.options = {
      ...Ue,
      ...e
    };
  }
  getPosition({ modifiers: e, value: t }) {
    return e.top ? "top" : e.bottom ? "bottom" : e.left ? "left" : e.right ? "right" : typeof t == "string" ? "top" : t.position ?? this.options.position;
  }
  getText({ value: e }) {
    return typeof e == "string" ? e : e.text;
  }
  getOpen({ value: e }) {
    return typeof e == "string" ? !1 : e.open ?? !1;
  }
  getColor({ value: e }) {
    return typeof e == "string" ? "default" : e.color ?? "default";
  }
  getOffset({ value: e }) {
    return typeof e == "string" ? "1rem" : e.offset ?? "1rem";
  }
  create(e, t) {
    e.setAttribute("data-tooltip", this.getText(t));
    const n = this.getOffset(t);
    n && e.style.setProperty("--tooltip-offset", n), e.classList.add("m-tooltip");
    const o = this.getPosition(t);
    e.classList.add(`m-tooltip--${o}`), e.classList.add(`m-tooltip--${this.getColor(t)}`), this.getOpen(t) && e.classList.add("m-tooltip--open");
  }
  update(e, t) {
    this.remove(e, t), this.create(e, t);
  }
  remove(e, t) {
    e.removeAttribute("data-tooltip"), e.classList.remove("m-tooltip"), e.classList.remove("m-tooltip--top"), e.classList.remove("m-tooltip--bottom"), e.classList.remove("m-tooltip--left"), e.classList.remove("m-tooltip--right"), e.classList.remove("m-tooltip--open"), e.classList.remove(`m-tooltip--${this.getColor(t)}`);
  }
}
let Ae;
const io = {
  beforeMount(s, e) {
    const t = typeof e.value == "object" ? e.value : {};
    return Ae = new St(t), Ae.create(s, e);
  },
  updated(s, e) {
    return Ae.update(s, e);
  },
  unmounted(s, e) {
    return Ae.remove(s, e);
  }
}, Vs = {
  install: (s, e = Ue) => {
    const t = {
      ...Ue,
      ...e
    }, n = new St(t);
    s.directive("tooltip", {
      beforeMount: n.create.bind(n),
      updated: n.update.bind(n),
      unmounted: n.remove.bind(n)
    });
  }
};
function Ke(s, e) {
  let t = e == null ? void 0 : e.element;
  function n() {
    t && Ie(null, t);
  }
  const o = { ...e == null ? void 0 : e.props, ...e != null && e.addDestroyInProps ? { destroy: n } : {} }, i = ee(
    s,
    o,
    e == null ? void 0 : e.children
  );
  return e != null && e.app ? (i.appContext = e.app._context, t ? Ie(i, t) : typeof document < "u" && (t = document.createElement("div"), Ie(i, t))) : (t = t ?? document.body, Ie(i, t)), { vNode: i, destroy: n, el: t };
}
function Hs(s) {
  return !!s;
}
function ro(s) {
  return [...s].map((e) => {
    const t = e.codePointAt(0);
    return t ? t % 32 + 127461 : void 0;
  }).filter(Hs).map((e) => String.fromCodePoint(e)).join("");
}
function ao(s, e) {
  let t;
  return function(...n) {
    clearTimeout(t), t = setTimeout(() => {
      s.apply(this, n);
    }, e);
  };
}
class lo {
  constructor({ src: e, identifier: t, once: n = !0, async: o = !0, defer: i = !0 }) {
    c(this, "src");
    c(this, "script");
    c(this, "once");
    c(this, "async");
    c(this, "defer");
    c(this, "identifier");
    if (typeof window > "u")
      throw new TypeError("[ScriptLoader]: Is supported only on browser side");
    if (!e)
      throw new Error('[ScriptLoader]: You should provide the attribut "src"');
    if (!t)
      throw new Error('[ScriptLoader]: You should provide the attribut "identifier"');
    this.src = e, this.identifier = t, this.once = n, this.async = o, this.defer = i;
  }
  removeTag(e) {
    var t;
    typeof e == "string" ? (t = document.head.querySelector(`[data-identifier="${e}"]`)) == null || t.remove() : e.remove();
  }
  load() {
    const e = window, t = document.head.querySelectorAll(`[data-identifier="${this.identifier}"]`);
    if (this.once && e[this.identifier] && t.length > 0)
      return this.script = e[this.identifier], Promise.resolve(this.script);
    if (!this.once && t.length > 0)
      for (const n of t)
        this.removeTag(n);
    return this.injectScript();
  }
  injectScript() {
    const e = window;
    return new Promise((t, n) => {
      try {
        const o = document.createElement("script");
        o.src = this.src, o.async = this.async, o.defer = this.defer, o.dataset.identifier = this.identifier, o.addEventListener("error", (i) => n(new Error(`[ScriptLoader](injectScript) ${i.message}`))), o.addEventListener("load", (i) => (this.script = i, e[this.identifier] = i, t(i))), document.head.append(o);
      } catch (o) {
        throw new Error(`[ScriptLoader](init) ${o}`);
      }
    });
  }
}
function Bs(s) {
  return new Promise((e) => setTimeout(e, s));
}
function uo(s, e) {
  let t = !1, n, o;
  return function(...i) {
    t ? (clearTimeout(n), n = setTimeout(() => {
      Date.now() - o >= e && (s.apply(this, i), o = Date.now());
    }, Math.max(e - (Date.now() - o), 0))) : (s.apply(this, i), o = Date.now(), t = !0);
  };
}
const Ns = ["onKeypress"], Fs = { class: "m-fullscreen-img-scroller" }, js = ["src", "alt"], qs = /* @__PURE__ */ be({
  __name: "MazFullscreenImg",
  props: {
    src: {},
    clickedElementBounds: { default: void 0 },
    offset: { default: void 0 },
    animation: { default: () => ({
      duration: 300,
      easing: "ease-in-out"
    }) },
    openInstanceClass: { default: "m-fullscreen-img-instance" },
    clickedElement: {},
    destroy: { type: Function, default: void 0 },
    alt: { default: void 0 },
    zoom: { type: Boolean, default: !0 }
  },
  emits: ["close", "previous", "next", "before-close"],
  setup(s, { emit: e }) {
    const t = s, n = e, o = H(() => import("./MazSpinner-B6NPqEic.mjs")), i = H(() => import("./x-mark-CnZwVvA7.mjs")), r = H(() => import("./chevron-left-BqVOLRdC.mjs")), a = b(!1), l = b(!1), u = b(!1), y = b(!1), T = b(!1), w = Ft({
      running: !1,
      ended: !1
    }), f = b(t.clickedElement), k = h(() => t.clickedElement.getBoundingClientRect()), z = b(), m = b(t.src), d = b(t.alt), C = b(), p = b(), S = b(!0), D = h(() => ({
      "--is-zoomed": T.value,
      "--invisible": S.value,
      "--absolute": !T.value
    }));
    function L() {
      var g, E;
      p.value && (z.value = ((g = p.value) == null ? void 0 : g.naturalWidth) > ((E = p.value) == null ? void 0 : E.naturalHeight)), a.value = !0, l.value = !1, u.value = !0;
    }
    G(
      u,
      (g) => {
        g && x();
      },
      { immediate: !0 }
    );
    function P() {
      n("before-close"), M();
    }
    function j(g) {
      g.key === "Escape" && (g.preventDefault(), P()), (g.key === "ArrowLeft" || g.key === "ArrowRight") && (g.preventDefault(), fe(g.key === "ArrowRight" ? "next" : "previous"));
    }
    function ne() {
      document.documentElement.classList.add("--m-fullscreen-open");
    }
    function Q() {
      document.documentElement.classList.remove("--m-fullscreen-open");
    }
    function de() {
      return [...document.querySelectorAll(".m-fullscreen-img-instance")];
    }
    function $e(g, E) {
      return E < 0 ? g.length - 1 : E >= g.length ? 0 : E;
    }
    function me(g, E) {
      g.classList.remove(t.openInstanceClass), E.classList.add(t.openInstanceClass);
      const I = E.getAttribute("data-src"), $ = E.getAttribute("data-alt");
      d.value = $, m.value = I ?? m.value;
    }
    function fe(g) {
      S.value = !0;
      const E = document.querySelector(
        `.m-fullscreen-img-instance.${t.openInstanceClass}`
      );
      if (E) {
        const I = de(), $ = I.indexOf(E), _ = g === "next" ? $ + 1 : $ - 1, K = I[$e(I, _)];
        f.value = K, K && me(E, K), n(g), a.value = !1, l.value = !0, mt(() => a.value === !0, () => {
          S.value = !1, T.value ? he() : v();
        }, {
          expectedValue: !0,
          interval: 100,
          maxAttempts: 50
        });
      }
    }
    function he() {
      const g = p.value;
      if (!g) {
        console.error("[maz-ui](vFullscreenImg) ImgElement is not defined");
        return;
      }
      g.style.removeProperty("max-width"), g.style.removeProperty("max-height"), g == null || g.style.removeProperty("top"), g == null || g.style.removeProperty("left"), z.value ? (g.style.height = "100vh", g.style.removeProperty("width")) : (g.style.width = "100vw", g.style.removeProperty("height"));
    }
    function Ve() {
      T.value ? (T.value = !T.value, v()) : (T.value = !T.value, he());
    }
    function oe(g) {
      var I;
      w.running = !0, S.value = !1;
      const E = (I = p.value) == null ? void 0 : I.animate(g, {
        duration: t.animation.duration,
        easing: t.animation.easing
      });
      if (!E) {
        console.error("[maz-ui](vFullscreenImg) animation is not defined"), w.running = !1, w.ended = !0;
        return;
      }
      return E;
    }
    function ke(g = t.offset ?? 0) {
      const E = f.value.clientWidth || 1, I = f.value.clientHeight || 1, $ = window.innerWidth, _ = window.innerHeight, K = Math.min(
        ($ - 2 * g) / E,
        (_ - 2 * g) / I
      ), He = ($ - E * K) / 2, Be = (_ - I * K) / 2;
      return {
        centerX: He,
        centerY: Be,
        width: E,
        height: I,
        scale: K
      };
    }
    function Ce({ trigger: g }) {
      const { width: E, height: I, scale: $, centerX: _, centerY: K } = ke(), { top: He, left: Be, width: Pt, height: Mt } = k.value, Ye = [
        {
          top: `${He}px`,
          left: `${Be}px`,
          width: `${Pt}px`,
          height: `${Mt}px`,
          opacity: 0
        },
        {
          top: `${K}px`,
          left: `${_}px`,
          width: `${E * $}px`,
          height: `${I * $}px`,
          opacity: 1
        }
      ];
      return {
        frames: g === "open" ? Ye : Ye.reverse()
      };
    }
    function v() {
      const { height: g, width: E, scale: I } = ke(), $ = z.value ? {
        width: `${E * I}px`,
        maxHeight: `${g * I}px`
      } : {
        height: `${g * I}px`,
        maxWidth: `${E * I}px`
      };
      if (!p.value) {
        console.error("[maz-ui](vFullscreenImg) ImgElement is not defined");
        return;
      }
      z.value ? (p.value.style.removeProperty("height"), p.value.style.removeProperty("maxHeight")) : (p.value.style.removeProperty("width"), p.value.style.removeProperty("maxWidth")), Object.assign(p.value.style, $);
    }
    function x() {
      const { frames: g } = Ce({
        trigger: "open"
      }), E = oe(g);
      if (!E) {
        console.error("[maz-ui](vFullscreenImg) open animation is not defined"), v();
        return;
      }
      E.onfinish = () => {
        v(), w.running = !1, w.ended = !0;
      };
    }
    function M() {
      const { frames: g } = Ce({
        trigger: "close"
      }), E = oe(g);
      function I() {
        var $, _;
        n("close"), ($ = C.value) == null || $.remove(), (_ = t.destroy) == null || _.call(t), w.running = !1, w.ended = !0;
      }
      if (!E) {
        console.error("[maz-ui](vFullscreenImg) close animation is not defined"), I();
        return;
      }
      E.onfinish = I;
    }
    function ve() {
      T.value || v();
    }
    return ce(() => {
      l.value = !0, document.addEventListener("keydown", j), window.addEventListener("resize", ve), ne(), y.value = de().length > 1;
    }), lt(() => {
      document.removeEventListener("keydown", j), window.removeEventListener("resize", ve), Q();
    }), (g, E) => (A(), U("div", {
      ref_key: "FullscreenImgElement",
      ref: C,
      role: "button",
      class: "m-fullscreen-img m-reset-css",
      tabindex: "0",
      onClick: Z(P, ["stop"]),
      onKeypress: jt(Z(P, ["prevent"]), ["esc"])
    }, [
      u.value && y.value ? (A(), U("button", {
        key: 0,
        type: "button",
        class: "m-fullscreen-btn --next",
        onClick: E[0] || (E[0] = Z((I) => fe("next"), ["stop"]))
      }, [
        ee(O(r), { class: "maz-rotate-180" })
      ])) : B("v-if", !0),
      u.value && y.value ? (A(), U("button", {
        key: 1,
        type: "button",
        class: "m-fullscreen-btn --previous",
        onClick: E[1] || (E[1] = Z((I) => fe("previous"), ["stop"]))
      }, [
        ee(O(r))
      ])) : B("v-if", !0),
      V("button", {
        type: "button",
        class: "m-fullscreen-btn --close",
        onClick: P
      }, [
        ee(O(i))
      ]),
      V("div", Fs, [
        V("img", {
          ref_key: "ImgElement",
          ref: p,
          src: m.value,
          alt: d.value ?? void 0,
          tabindex: "0",
          class: ue([D.value]),
          onLoad: L,
          onClick: E[2] || (E[2] = Z((I) => g.zoom && Ve(), ["stop"]))
        }, null, 42, js),
        ut(ee(
          O(o),
          { class: "m-fullscreen-img-loader" },
          null,
          512
          /* NEED_PATCH */
        ), [
          [ct, l.value]
        ])
      ])
    ], 40, Ns));
  }
}), Me = (s, e) => {
  const t = s.__vccOpts || s;
  for (const [n, o] of e)
    t[n] = o;
  return t;
}, Us = /* @__PURE__ */ Me(qs, [["__scopeId", "data-v-24a18baf"]]), tt = "m-fullscreen-is-open";
class Ws {
  constructor() {
    c(this, "options");
    c(this, "defaultOptions", {
      scaleOnHover: !1,
      blurOnHover: !1,
      disabled: !1,
      zoom: !0,
      offset: 80,
      animation: {
        duration: 300,
        easing: "ease-in-out"
      }
    });
    c(this, "mouseEnterListener");
    c(this, "mouseLeaveListener");
    c(this, "renderPreviewListener");
  }
  buildOptions(e, t) {
    const n = typeof t.value == "object" ? t.value : { src: t.value, alt: void 0 }, o = (n == null ? void 0 : n.src) ?? this.getImgSrc(e), i = (n == null ? void 0 : n.alt) ?? this.getImgAlt(e);
    return {
      ...this.defaultOptions,
      ...n,
      src: o,
      alt: i
    };
  }
  get allInstances() {
    return [...document.querySelectorAll(".m-fullscreen-img-instance")];
  }
  getImgSrc(e) {
    var n;
    const t = ((n = this.options) == null ? void 0 : n.src) || e.getAttribute("src") || e.getAttribute("data-src");
    if (!t)
      throw new Error(
        '[maz-ui](fullscreen-img) src of image must be provided by `v-fullscreen=""`, `v-fullscreen="{ src: "" }"`, `src=""` or `data-src=""` atributes'
      );
    return t;
  }
  getImgAlt(e) {
    var t;
    return ((t = this.options) == null ? void 0 : t.alt) || e.getAttribute("alt") || e.getAttribute("data-alt");
  }
  create(e, t) {
    if (this.options = this.buildOptions(e, t), this.options.disabled)
      return;
    e.style.cursor = "move", (this.options.scaleOnHover || this.options.blurOnHover) && (e.style.transition = "all 200ms ease-in-out"), e.classList.add("m-fullscreen-img-instance"), e.setAttribute("data-src", this.getImgSrc(e));
    const n = this.getImgAlt(e);
    n && e.setAttribute("data-alt", n), this.mouseEnterListener = () => this.mouseEnter(e), this.mouseLeaveListener = () => this.mouseLeave(e), this.renderPreviewListener = () => this.renderPreview(e), e.addEventListener("mouseenter", this.mouseEnterListener), e.addEventListener("mouseleave", this.mouseLeaveListener), e.addEventListener("click", this.renderPreviewListener);
  }
  update(e, t) {
    this.options = this.buildOptions(e, t);
  }
  remove(e) {
    e.removeEventListener("mouseenter", this.mouseEnterListener), e.removeEventListener("mouseleave", this.mouseLeaveListener), e.removeEventListener("click", this.renderPreviewListener), e.classList.remove("m-fullscreen-img-instance"), e.style.cursor = "";
  }
  renderPreview(e) {
    return e.classList.add(tt), Ke(Us, {
      props: {
        ...this.options,
        openInstanceClass: tt,
        clickedElement: e,
        clickedElementBounds: e.getBoundingClientRect()
      },
      addDestroyInProps: !0
    });
  }
  mouseLeave(e) {
    this.options.scaleOnHover && (e.style.transform = ""), this.options.blurOnHover && (e.style.filter = ""), e.style.zIndex = "";
  }
  mouseEnter(e) {
    e.style.zIndex = "1", this.options.scaleOnHover && (e.style.transform = "scale(1.04)"), this.options.blurOnHover && (e.style.filter = "blur(3px)");
  }
}
let Se;
const Rs = {
  mounted(s, e) {
    return Se = new Ws(), Se.create(s, e);
  },
  updated(s, e) {
    return Se.update(s, e);
  },
  unmounted(s) {
    return Se.remove(s);
  }
}, _s = {
  install(s) {
    s.directive("fullscreen-img", Rs);
  }
}, Ks = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7", We = {
  baseClass: "m-lazy-img",
  loadedClass: "m-lazy-loaded",
  loadingClass: "m-lazy-loading",
  errorClass: "m-lazy-error",
  noPhotoClass: "m-lazy-no-photo",
  noPhoto: !1,
  observerOnce: !0,
  loadOnce: !1,
  noUseErrorPhoto: !1,
  observerOptions: {
    threshold: 0.1
  }
};
class xt {
  constructor(e = {}) {
    c(this, "observers", []);
    c(this, "defaultOptions", We);
    c(this, "options");
    c(this, "onImgLoadedCallback");
    c(this, "onImgErrorCallback");
    c(this, "hasImgLoaded", !1);
    this.options = this.buildOptions(e), this.onImgLoadedCallback = this.imageIsLoaded.bind(this), this.onImgErrorCallback = this.imageHasError.bind(this);
  }
  async loadErrorPhoto() {
    const { default: e } = await import("./no-photography-BJX8HSus.mjs");
    return e;
  }
  buildOptions(e) {
    return {
      ...this.defaultOptions,
      ...e,
      observerOptions: {
        ...this.defaultOptions.observerOptions,
        ...e.observerOptions
      }
    };
  }
  removeClass(e, t) {
    e.classList.remove(t);
  }
  addClass(e, t) {
    e.classList.add(t);
  }
  removeAllStateClasses(e) {
    this.removeClass(e, this.options.loadedClass), this.removeClass(e, this.options.loadingClass), this.removeClass(e, this.options.errorClass), this.removeClass(e, this.options.noPhotoClass);
  }
  setBaseClass(e) {
    this.addClass(e, this.options.baseClass);
  }
  imageIsLoading(e) {
    var t, n;
    this.addClass(e, this.options.loadingClass), (n = (t = this.options).onLoading) == null || n.call(t, e);
  }
  imageHasNoPhoto(e) {
    this.removeClass(e, this.options.loadingClass), this.addClass(e, this.options.noPhotoClass), this.setDefaultPhoto(e);
  }
  imageIsLoaded(e) {
    var t, n;
    this.hasImgLoaded = !0, this.removeClass(e, this.options.loadingClass), this.addClass(e, this.options.loadedClass), (n = (t = this.options).onLoaded) == null || n.call(t, e);
  }
  imageHasError(e) {
    var t, n;
    this.removeClass(e, this.options.loadingClass), this.addClass(e, this.options.errorClass), (n = (t = this.options).onError) == null || n.call(t, e), this.setDefaultPhoto(e);
  }
  getSrc(e) {
    return typeof e.value == "object" ? e.value.src : e.value;
  }
  getImageUrl(e, t) {
    const n = this.getImgElement(e).getAttribute("data-lazy-src");
    return n || this.getSrc(t);
  }
  async setPictureSourceUrls(e) {
    const t = e.querySelectorAll("source");
    if (t.length > 0)
      for await (const n of t) {
        const o = n.getAttribute("data-lazy-srcset");
        if (o)
          n.srcset = o;
        else
          return this.imageHasError(e);
      }
    else
      this.imageHasError(e);
  }
  hasBgImgMode(e) {
    return e.arg === "bg-image";
  }
  isPictureElement(e) {
    return e instanceof HTMLPictureElement;
  }
  getImgElement(e) {
    return this.isPictureElement(e) ? e.querySelector("img") : e;
  }
  async setDefaultPhoto(e) {
    if (this.options.noUseErrorPhoto)
      return;
    const t = this.options.fallbackSrc ?? this.options.errorPhoto;
    typeof t == "string" && this.addClass(e, this.options.noPhotoClass);
    const n = t ?? await this.loadErrorPhoto(), o = e.querySelectorAll("source");
    if (o.length > 0)
      for await (const i of o)
        i.srcset = n;
    else
      this.setImgSrc(e, n);
  }
  addEventListenerToImg(e) {
    const t = this.getImgElement(e);
    t.addEventListener("load", () => this.onImgLoadedCallback(e), {
      once: !0
    }), t.addEventListener("error", (n) => this.onImgErrorCallback(e, n), { once: !0 });
  }
  async loadImage(e, t) {
    if (this.imageIsLoading(e), this.isPictureElement(e))
      this.addEventListenerToImg(e), await this.setPictureSourceUrls(e);
    else {
      const n = this.getImageUrl(e, t);
      if (!n)
        return this.imageHasError(e);
      this.hasBgImgMode(t) ? (e.style.backgroundImage = `url('${n}')`, this.imageIsLoaded(e)) : (this.addEventListenerToImg(e), this.setImgSrc(e, n));
    }
  }
  setImgSrc(e, t) {
    const n = this.getImgElement(e);
    n.src = t;
  }
  handleIntersectionObserver(e, t, n, o) {
    var i, r;
    this.observers.push(o);
    for (const a of n)
      if (a.isIntersecting) {
        if ((r = (i = this.options).onIntersecting) == null || r.call(i, a.target), this.options.observerOnce && o.unobserve(e), this.options.loadOnce && this.hasImgLoaded)
          return;
        this.loadImage(e, t);
      }
  }
  createObserver(e, t) {
    const n = (r, a) => {
      this.handleIntersectionObserver(e, t, r, a);
    }, o = this.options.observerOptions;
    new IntersectionObserver(n, o).observe(e);
  }
  async imageHandler(e, t, n) {
    if (n === "update")
      for await (const o of this.observers) o.unobserve(e);
    window.IntersectionObserver ? this.createObserver(e, t) : this.loadImage(e, t);
  }
  async bindUpdateHandler(e, t, n) {
    if (this.options.noPhoto)
      return this.imageHasNoPhoto(e);
    await this.imageHandler(e, t, n);
  }
  async add(e, t) {
    if (this.hasBgImgMode(t) && this.isPictureElement(e))
      throw new Error(`[MazLazyImg] You can't use the "bg-image" mode with "<picture />" element`);
    setTimeout(() => this.setBaseClass(e), 0), e.getAttribute("src") || this.setImgSrc(e, Ks), await this.bindUpdateHandler(e, t, "bind");
  }
  async update(e, t) {
    t.value !== t.oldValue && (this.hasImgLoaded = !1, this.removeAllStateClasses(e), await this.bindUpdateHandler(e, t, "update"));
  }
  remove(e, t) {
    this.hasImgLoaded = !1, this.hasBgImgMode(t) && (e.style.backgroundImage = ""), this.removeAllStateClasses(e);
    for (const n of this.observers)
      n.unobserve(e);
    this.observers = [];
  }
}
let xe;
const co = {
  created(s, e) {
    const t = typeof e.value == "object" ? e.value : {};
    xe = new xt(t), xe.add(s, e);
  },
  updated(s, e) {
    xe.update(s, e);
  },
  unmounted(s, e) {
    xe.remove(s, e);
  }
}, Ys = {
  install(s, e = {}) {
    const t = {
      ...We,
      ...e,
      observerOptions: {
        ...We.observerOptions,
        ...e.observerOptions
      }
    }, n = new xt(t);
    s.directive("lazy-img", {
      created: n.add.bind(n),
      updated: n.update.bind(n),
      unmounted: n.remove.bind(n)
    });
  }
}, Zs = `
.maz-zoom-img {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1050;
  background-color: hsla(238, 15%, 40%, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.maz-zoom-img,
.maz-zoom-img * {
  box-sizing: border-box;
}

.maz-zoom-img .maz-zoom-img__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: scale(0.5);
}

.maz-zoom-img.maz-animate .maz-zoom-img__wrapper {
  opacity: 1;
  transform: scale(1);
}

.maz-zoom-img.maz-animate .maz-zoom-img__loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(238, 15%, 40%, 0.7);
  border-radius: 1rem;
  z-index: 2;
  min-width: 60px;
  min-height: 60px;
}
.maz-zoom-img.maz-animate .maz-zoom-img__loader[hidden] {
  display: none;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.maz-zoom-img.maz-animate .maz-zoom-img__loader__svg {
  animation: spin .6s linear infinite;
}

.maz-zoom-img img {
  max-width: 100%;
  max-height: 100%;
  min-width: 0;
  border-radius: 1rem;
}

.maz-zoom-img .maz-zoom-btn {
  margin: 0 auto;
  border: none;
  background-color: hsla(0, 0%, 7%, 0.5);
  box-shadow: 0 0 0.5rem 0 hsla(0, 0%, 0%, 0.2);
  height: 2.2rem;
  min-height: 2.2rem;
  width: 2.2rem;
  min-width: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.2rem;
  cursor: pointer;
  flex: 0 0 auto;
  outline: none;
}

.maz-zoom-img .maz-zoom-btn svg {
  fill: white;
}

.maz-zoom-img .maz-zoom-btn.maz-zoom-btn--close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.maz-zoom-img .maz-zoom-btn.maz-zoom-btn--previous {
  position: absolute;
  left: 0.5rem;
  z-index: 1;
}

.maz-zoom-img .maz-zoom-btn.maz-zoom-btn--next {
  position: absolute;
  right: 0.5rem;
  z-index: 1;
}

.maz-zoom-img .maz-zoom-btn:hover {
  background-color: hsl(0, 0%, 0%);
}`, st = {
  close: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',
  next: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>',
  previous: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>',
  spinner: '<svg width="40px" height="40px" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" x="0px" y="0px" viewBox="0 0 50 50" xml:space="preserve" class="maz-zoom-img__loader__svg" data-v-6d1cb50c=""><path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"></path></svg>'
};
class Xs {
  constructor(e) {
    c(this, "options");
    c(this, "loader");
    c(this, "wrapper");
    c(this, "img");
    c(this, "keydownHandler");
    c(this, "onImgLoadedCallback");
    c(this, "buttonsAdded");
    c(this, "defaultOptions", {
      scale: !0,
      blur: !0,
      disabled: !1
    });
    c(this, "mouseEnterListener");
    c(this, "mouseLeaveListener");
    c(this, "renderPreviewListener");
    if (!e.value)
      throw new Error(
        '[MazUI](zoom-img) Image path must be defined. Ex: `v-zoom-img="<PATH_TO_IMAGE>"`'
      );
    if (typeof e.value == "object" && !e.value.src)
      throw new Error("[maz-ui](zoom-img) src of image must be provided");
    this.buttonsAdded = !1, this.options = this.buildOptions(e), this.keydownHandler = this.keydownLister.bind(this), this.loader = this.getLoader(), this.wrapper = document.createElement("div"), this.wrapper.classList.add("maz-zoom-img__wrapper"), this.wrapper.prepend(this.loader), this.img = document.createElement("img"), this.onImgLoadedCallback = this.onImgLoaded.bind(this), this.imgEventHandler(!0);
  }
  buildOptions(e) {
    return {
      ...this.defaultOptions,
      ...typeof e.value == "object" ? e.value : { src: e.value }
    };
  }
  get allInstances() {
    return [...document.querySelectorAll(".maz-zoom-img-instance")];
  }
  create(e) {
    this.options.disabled || (e.style.cursor = "pointer", setTimeout(() => e.classList.add("maz-zoom-img-instance")), e.setAttribute("data-zoom-src", this.options.src), this.options.alt && e.setAttribute("data-zoom-alt", this.options.alt), e.style.transition = "all 300ms ease-in-out", this.mouseEnterListener = () => this.mouseEnter(e), this.mouseLeaveListener = () => this.mouseLeave(e), this.renderPreviewListener = () => this.renderPreview(e, this.options), e.addEventListener("mouseenter", this.mouseEnterListener), e.addEventListener("mouseleave", this.mouseLeaveListener), e.addEventListener("click", this.renderPreviewListener));
  }
  update(e) {
    this.options = this.buildOptions(e);
  }
  remove(e) {
    this.imgEventHandler(!1), e.removeEventListener("mouseenter", this.mouseEnterListener), e.removeEventListener("mouseleave", this.mouseLeaveListener), e.removeEventListener("click", this.renderPreviewListener), e.classList.remove("maz-zoom-img-instance"), e.removeAttribute("data-zoom-src"), e.removeAttribute("data-zoom-alt"), e.style.cursor = "";
  }
  renderPreview(e, t) {
    e.classList.add(
      "maz-is-open"
      /* OPEN */
    ), this.addStyle(Zs);
    const n = document.createElement("div");
    n.classList.add("maz-zoom-img"), n.setAttribute("id", "MazImgPreviewFullsize"), n.addEventListener("click", (o) => {
      n.isEqualNode(o.target) && this.closePreview();
    }), typeof t == "object" && (this.img.setAttribute("src", t.src), t.alt && this.img.setAttribute("alt", t.alt), this.img.id = "MazImgElement"), this.wrapper.append(this.img), n.append(this.wrapper), document.body.append(n), this.keyboardEventHandler(!0), setTimeout(() => {
      n && n.classList.add("maz-animate");
    }, 100);
  }
  onImgLoaded() {
    this.wrapper.style.width = `${this.img.width}px`, this.wrapper.style.minWidth = "200px", this.loader.hidden = !0;
    const e = this.getButton(), t = [], n = this.allInstances.length > 1;
    if (!this.buttonsAdded) {
      if (this.buttonsAdded = !0, n) {
        const o = this.getButton("previous"), i = this.getButton("next");
        t.push(o, i);
      }
      this.wrapper.append(e), n && (this.wrapper.prepend(t[0]), this.wrapper.append(t[1]));
    }
  }
  getLoader() {
    const e = document.createElement("div");
    return e.classList.add("maz-zoom-img__loader"), e.innerHTML = st.spinner, e;
  }
  mouseLeave(e) {
    this.options.scale && (e.style.transform = ""), this.options.blur && (e.style.filter = ""), e.style.zIndex = "";
  }
  mouseEnter(e) {
    e.style.zIndex = "1", this.options.scale && (e.style.transform = "scale(1.1)"), this.options.blur && (e.style.filter = "blur(2px)");
  }
  keydownLister(e) {
    e.preventDefault(), (e.key === "Escape" || e.key === " ") && this.closePreview(), (e.key === "ArrowLeft" || e.key === "ArrowRight") && this.nextPreviousImage(e.key === "ArrowRight");
  }
  getButton(e = "close") {
    const t = document.createElement("button");
    t.innerHTML = st[e];
    const n = () => e === "close" ? this.closePreview() : this.allInstances ? this.nextPreviousImage(e === "next") : null;
    return t.addEventListener("click", () => {
      n();
    }), t.classList.add("maz-zoom-btn"), t.classList.add(`maz-zoom-btn--${e}`), t;
  }
  closePreview() {
    const e = document.querySelector("#MazImgPreviewFullsize"), t = document.querySelector("#MazPreviewStyle"), n = document.querySelector(
      ".maz-zoom-img-instance.maz-is-open"
    );
    n && n.classList.remove(
      "maz-is-open"
      /* OPEN */
    ), e && e.classList.remove("maz-animate"), this.keyboardEventHandler(!1), setTimeout(() => {
      e && e.remove(), t && t.remove();
    }, 300);
  }
  getNewInstanceIndex(e) {
    let t = e;
    return t < 0 ? t = this.allInstances.length - 1 : t >= this.allInstances.length && (t = 0), t;
  }
  nextPreviousImage(e) {
    const t = e, n = document.querySelector(
      ".maz-zoom-img-instance.maz-is-open"
    );
    if (n) {
      const o = this.allInstances.indexOf(n), i = t ? o + 1 : o - 1, r = this.allInstances[this.getNewInstanceIndex(i)];
      r && this.useNextInstance(n, r);
    }
  }
  useNextInstance(e, t) {
    e.classList.remove(
      "maz-is-open"
      /* OPEN */
    ), t.classList.add(
      "maz-is-open"
      /* OPEN */
    );
    const n = t.getAttribute("data-zoom-src"), o = t.getAttribute("data-zoom-alt");
    this.wrapper.style.width = "", this.loader.hidden = !1, n && this.img.setAttribute("src", n), o && this.img.setAttribute("alt", o);
  }
  addStyle(e) {
    const t = document.createElement("style");
    t.id = "MazPreviewStyle", t.textContent = e, document.head.append(t);
  }
  keyboardEventHandler(e) {
    if (e)
      return document.addEventListener("keydown", this.keydownHandler);
    document.removeEventListener("keydown", this.keydownHandler);
  }
  imgEventHandler(e) {
    if (e)
      return this.img.addEventListener("load", this.onImgLoadedCallback);
    this.img.removeEventListener("load", this.onImgLoadedCallback);
  }
}
let Oe;
const Gs = {
  created(s, e) {
    Oe = new Xs(e), Oe.create(s);
  },
  updated(s, e) {
    Oe.update(e);
  },
  unmounted(s) {
    Oe.remove(s);
  }
}, Qs = {
  install(s) {
    s.directive("zoom-img", Gs);
  }
}, Js = [
  $s,
  Qs,
  Ys,
  Os,
  _s,
  Vs
], mo = {
  install(s) {
    for (const e of Js)
      e.install(s, {});
  }
};
function en(s) {
  return s ? (s = s.toString(), s.charAt(0).toUpperCase() + s.slice(1)) : "";
}
const tn = {
  style: "currency",
  minimumFractionDigits: 2,
  round: !1
};
function sn(s, e, t) {
  let n = Number(s);
  return t.round && (n = Math.floor(n), t.minimumFractionDigits = 0), new Intl.NumberFormat(e, t).format(n);
}
function nn(s, e, t) {
  if (s === void 0)
    throw new TypeError("[maz-ui](FilterCurrency) The `number` attribute is required.");
  if (e === void 0)
    throw new TypeError("[maz-ui](FilterCurrency) The `locale` attribute is required.");
  if (typeof e != "string")
    throw new TypeError("[maz-ui](FilterCurrency) The `locale` attribute must be a string.");
  if (t.currency === void 0)
    throw new TypeError("[maz-ui](FilterCurrency) The `options.currency` attribute is required.");
}
function on(s, e, t) {
  const n = {
    ...tn,
    ...t
  };
  nn(s, e, n);
  try {
    return sn(s, e, n);
  } catch (o) {
    throw new Error(`[maz-ui](FilterCurrency) ${o}`);
  }
}
const rn = {
  month: "short",
  day: "numeric",
  year: "numeric"
};
function an(s, e, t) {
  if (e === void 0)
    throw new TypeError("[maz-ui](FilterDate) The `locale` attribute is required.");
  if (typeof e != "string")
    throw new TypeError("[maz-ui](FilterDate) The `locale` attribute must be a string.");
  const n = t ?? rn;
  try {
    const o = s instanceof Date ? s : new Date(s);
    return new Intl.DateTimeFormat(e, n).format(o);
  } catch (o) {
    throw new Error(`[maz-ui](FilterDate) ${o}`);
  }
}
const ln = {
  minimumFractionDigits: 2
};
function un(s, e, t) {
  const n = {
    ...ln,
    ...t
  };
  if (s === void 0)
    throw new TypeError("[maz-ui](FilterNumber) The `number` attribute is required.");
  if (e === void 0)
    throw new TypeError("[maz-ui](FilterNumber) The `locale` attribute is required.");
  if (typeof e != "string")
    throw new TypeError("[maz-ui](FilterNumber) The `locale` attribute must be a string.");
  try {
    return new Intl.NumberFormat(e, n).format(Number(s));
  } catch (o) {
    throw new Error(`[maz-ui](FilterNumber) ${o}`);
  }
}
const cn = {
  capitalize: en,
  currency: on,
  date: an,
  number: un
}, fo = {
  install(s) {
    s.provide("filters", cn);
  }
}, je = {
  delay: 100,
  observer: {
    root: void 0,
    rootMargin: "0px",
    threshold: 0.2
  },
  animation: {
    once: !0,
    duration: 300,
    delay: 0
  }
};
class dn {
  constructor(e) {
    c(this, "options");
    this.options = {
      delay: (e == null ? void 0 : e.delay) ?? je.delay,
      observer: {
        ...je.observer,
        ...e == null ? void 0 : e.observer
      },
      animation: {
        ...je.animation,
        ...e == null ? void 0 : e.animation
      }
    };
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleIntersect(e, t) {
    for (const n of e) {
      const i = n.target.getAttribute("data-maz-aos-children") === "true", r = n.target.getAttribute("data-maz-aos") ? [n.target] : [];
      if (i) {
        const a = [...document.querySelectorAll("[data-maz-aos-anchor]")].map(
          (l) => l.getAttribute("data-maz-aos-anchor") === `#${n.target.id}` ? l : void 0
        );
        for (const l of a)
          l && r.push(l);
      }
      for (const a of r) {
        const l = a.getAttribute("data-maz-aos-once"), u = typeof l == "string" ? l === "true" : this.options.animation.once;
        if (typeof this.options.observer.threshold == "number" && n.intersectionRatio > this.options.observer.threshold) {
          const y = a.getAttribute("data-maz-aos-duration"), T = a.getAttribute("data-maz-aos-delay");
          if (y || (a.style.transitionDuration = `${this.options.animation.duration}ms`, setTimeout(() => {
            a.style.transitionDuration = "0";
          }, 1e3)), T || (a.style.transitionDelay = `${this.options.animation.delay}ms`, setTimeout(() => {
            a.style.transitionDelay = "0";
          }, 1e3)), a.classList.add("maz-aos-animate"), u) {
            const w = a.getAttribute("data-maz-aos-anchor");
            if (w) {
              const f = document.querySelector(w);
              f && t.unobserve(f);
            }
            t.unobserve(a);
          }
        } else
          a.classList.remove("maz-aos-animate");
      }
    }
  }
  async handleObserver() {
    await Bs(this.options.delay);
    const e = new IntersectionObserver(this.handleIntersect.bind(this), this.options.observer);
    for (const t of document.querySelectorAll("[data-maz-aos]")) {
      const n = t.getAttribute("data-maz-aos-anchor");
      if (n) {
        const o = document.querySelector(n);
        o ? (o.setAttribute("data-maz-aos-children", "true"), e.observe(o)) : console.warn(`[maz-ui](aos) no element found with selector "${n}"`);
      } else
        e.observe(t);
    }
  }
  runAnimations() {
    if (F())
      return this.handleObserver();
    console.warn("[MazAos](runAnimations) should be executed on client side");
  }
}
let pe;
const ho = {
  install: (s, e) => {
    pe = new dn(e), s.provide("aos", pe), F() && (e != null && e.router ? e.router.afterEach(() => {
      pe.runAnimations();
    }) : pe.runAnimations());
  }
};
function vo() {
  return pe;
}
const mn = {
  role: "dialog",
  class: "m-backdrop-container",
  "aria-modal": "true"
}, fn = { class: "m-backdrop-wrapper" }, nt = "--backdrop-present", hn = /* @__PURE__ */ be({
  inheritAttrs: !1,
  __name: "MazBackdrop",
  props: {
    modelValue: { type: Boolean, default: !1 },
    teleportSelector: { default: "body" },
    beforeClose: { type: Function, default: void 0 },
    persistent: { type: Boolean, default: !1 },
    noCloseOnEscKey: { type: Boolean, default: !1 },
    transitionName: { default: "backdrop-anim" },
    backdropClass: { default: () => {
    } },
    backdropContentClass: { default: () => {
    } },
    contentPadding: { type: Boolean, default: !1 },
    justify: { default: "none" },
    align: { default: "none" },
    variant: {}
  },
  emits: ["open", "close", "update:model-value", "before-close"],
  setup(s, { expose: e, emit: t }) {
    const n = t;
    function o() {
      document.documentElement.classList.add(nt);
    }
    function i() {
      document.querySelector(".m-backdrop.--present") || document.documentElement.classList.remove(nt);
    }
    const r = b(s.modelValue);
    function a() {
      l(!1);
    }
    async function l(d) {
      var C;
      d || (n("before-close"), await ((C = s.beforeClose) == null ? void 0 : C.call(s))), r.value = d;
    }
    function u() {
      n("open");
    }
    function y() {
      n("update:model-value", !1), n("close"), k();
    }
    function T() {
      s.persistent || a();
    }
    function w(d) {
      !s.noCloseOnEscKey && d.key === "Escape" && !s.persistent && a();
    }
    function f() {
      o(), document.addEventListener("keyup", w, !1);
    }
    function k() {
      document.removeEventListener("keyup", w), i();
    }
    ce(() => {
      s.modelValue ? f() : k();
    });
    let z = null;
    function m(d) {
      const C = document.querySelector(d), S = C && Array.from(C.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select')), D = S == null ? void 0 : S.filter((L) => {
        const P = window.getComputedStyle(L);
        return P.display !== "none" && P.visibility !== "hidden" && !L.disabled;
      });
      return D && D.length > 0 ? D[0] : null;
    }
    return G(
      () => s.modelValue,
      async (d) => {
        if (r.value = d, d) {
          f(), z = document.activeElement, await le();
          const C = m(".m-backdrop-content");
          C == null || C.focus();
        } else
          k(), await le(), z == null || z.focus();
      }
    ), e({
      onBackdropAnimationEnter: u,
      onBackdropAnimationLeave: y,
      onBackdropClicked: T,
      close: a,
      present: r,
      toggleModal: l,
      onKeyPress: w
    }), (d, C) => (A(), N(qt, { to: d.teleportSelector }, [
      ee(dt, {
        appear: "",
        name: d.transitionName,
        onAfterEnter: u,
        onAfterLeave: y
      }, {
        default: q(() => [
          r.value ? (A(), U(
            "div",
            te({
              key: 0,
              class: "m-backdrop --present m-reset-css"
            }, d.$attrs, {
              class: [d.backdropClass, d.variant && `--variant-${d.variant}`, { "--persistent": d.persistent }]
            }),
            [
              V("div", mn, [
                V("div", fn, [
                  V(
                    "div",
                    {
                      class: ue(["m-backdrop-content", [d.backdropContentClass, `--justify-${d.justify}`, `--align-${d.align}`, { "--padding": d.contentPadding }]]),
                      role: "button",
                      tabindex: "-1",
                      onClick: Z(T, ["self"])
                    },
                    [
                      R(d.$slots, "default", {
                        close: a,
                        onBackdropClicked: T
                      }, void 0, !0)
                    ],
                    2
                    /* CLASS */
                  )
                ])
              ])
            ],
            16
            /* FULL_PROPS */
          )) : B("v-if", !0)
        ]),
        _: 3
        /* FORWARDED */
      }, 8, ["name"])
    ], 8, ["to"]));
  }
}), vn = /* @__PURE__ */ Me(hn, [["__scopeId", "data-v-90448c91"]]), pn = {
  key: 0,
  id: "dialogTitle",
  class: "m-dialog-title"
}, gn = {
  key: 0,
  class: "m-dialog-footer"
}, yn = /* @__PURE__ */ be({
  name: "MazDialog",
  inheritAttrs: !1,
  __name: "MazDialog",
  props: {
    modelValue: { type: Boolean },
    title: { default: void 0 },
    noClose: { type: Boolean, default: !1 },
    maxWidth: { default: "100%" },
    minWidth: { default: "32rem" },
    scrollable: { type: Boolean, default: !1 },
    persistent: { type: Boolean, default: !1 },
    teleportSelector: {},
    beforeClose: {},
    noCloseOnEscKey: { type: Boolean },
    transitionName: {},
    backdropClass: {},
    backdropContentClass: {},
    contentPadding: { type: Boolean },
    justify: {},
    align: {},
    variant: {}
  },
  emits: ["open", "close", "update:model-value"],
  setup(s, { expose: e }) {
    const t = s, n = H(() => import("./MazBtn-UGfo85SW.mjs")), o = H(() => import("./x-mark-CnZwVvA7.mjs")), i = Ut(), r = b();
    e({
      close: () => {
        var f, k;
        return (k = (f = r.value) == null ? void 0 : f.close) == null ? void 0 : k.call(f);
      }
    });
    const a = h(() => {
      const f = [
        "modelValue",
        "title",
        "noClose",
        "maxWidth",
        "minWidth",
        "scrollable"
      ];
      return Object.fromEntries(
        Object.entries(t).filter(([k]) => !f.includes(k))
      );
    }), l = h(() => ({
      ...i,
      class: void 0,
      style: void 0
    })), u = h(() => ({
      class: i.class,
      style: i.style
    })), y = b(), T = Wt(), w = h(() => !!T.footer);
    return t.scrollable && G(() => t.modelValue, async (f) => {
      await le(), f && y.value && setTimeout(() => {
        var k;
        (k = y.value) == null || k.scrollTo({ top: 0, behavior: "instant" });
      }, 0);
    }), (f, k) => (A(), N(vn, te({ ...l.value, ...a.value }, {
      ref_key: "backdrop",
      ref: r,
      "model-value": f.modelValue,
      "transition-name": "modal-anim",
      "aria-labelledby": "dialogTitle",
      "aria-describedby": "dialogDesc",
      "content-padding": "",
      justify: "center",
      variant: "dialog",
      onClose: k[0] || (k[0] = (z) => f.$emit("close", z)),
      onOpen: k[1] || (k[1] = (z) => f.$emit("open", z)),
      "onUpdate:modelValue": k[2] || (k[2] = (z) => f.$emit("update:model-value", z))
    }), {
      default: q(({ close: z, onBackdropClicked: m }) => [
        V(
          "div",
          te({
            class: ["m-dialog", { "--scrollable": f.scrollable }],
            role: "dialog",
            "aria-modal": "true",
            style: [{ "--max-width": f.maxWidth, "--min-width": f.minWidth }]
          }, u.value),
          [
            R(f.$slots, "header", {
              close: z,
              onBackdropClicked: m
            }, () => [
              V(
                "div",
                {
                  class: ue(["m-dialog-header", { "--has-title": f.$slots.title || f.title }])
                },
                [
                  f.$slots.title || f.title ? (A(), U("h2", pn, [
                    R(f.$slots, "title", {}, () => [
                      J(
                        X(f.title),
                        1
                        /* TEXT */
                      )
                    ], !0)
                  ])) : B("v-if", !0),
                  !f.noClose && !f.persistent ? (A(), N(O(n), {
                    key: 1,
                    color: "transparent",
                    size: "sm",
                    icon: O(o),
                    onClick: z
                  }, null, 8, ["icon", "onClick"])) : B("v-if", !0)
                ],
                2
                /* CLASS */
              )
            ], !0),
            V(
              "div",
              {
                id: "dialogDesc",
                ref_key: "dialogContent",
                ref: y,
                class: ue(["m-dialog-content", { "--bottom-padding": !w.value }])
              },
              [
                R(f.$slots, "default", {
                  close: z,
                  onBackdropClicked: m
                }, void 0, !0)
              ],
              2
              /* CLASS */
            ),
            w.value ? (A(), U("div", gn, [
              R(f.$slots, "footer", {
                close: z,
                onBackdropClicked: m
              }, void 0, !0)
            ])) : B("v-if", !0)
          ],
          16
          /* FULL_PROPS */
        )
      ]),
      _: 3
      /* FORWARDED */
    }, 16, ["model-value"]));
  }
}), bn = /* @__PURE__ */ Me(yn, [["__scopeId", "data-v-b8a6c7fb"]]), ie = {
  cancelText: "Cancel",
  confirmText: "Confirm",
  cancelButton: {
    text: "Cancel",
    color: "danger"
  },
  confirmButton: {
    text: "Confirm",
    color: "success"
  }
}, wn = b(ie), re = b([]);
function En(s, e) {
  return new Promise((t, n) => {
    re.value = [
      ...re.value,
      {
        id: s,
        isActive: !0,
        resolve: async (o) => {
          t(o), await (e == null ? void 0 : e());
        },
        reject: async (o) => {
          n(o), await (e == null ? void 0 : e());
        }
      }
    ];
  });
}
function Ot(s) {
  return re.value = re.value.filter(({ id: e }) => e !== s), re.value;
}
function ot(s, e, t) {
  var n;
  e && ((n = e[s]) == null || n.call(e, t), e.isActive = !1, setTimeout(() => {
    Ot(e.id);
  }, 500));
}
function Dt() {
  return {
    data: wn,
    dialogState: re,
    showDialogAndWaitChoice: En,
    removeDialogFromState: Ot,
    rejectDialog: async (s, e = new Error("cancel"), t) => (await (t == null ? void 0 : t()), ot("reject", s, e)),
    resolveDialog: async (s, e = "accept", t) => (await (t == null ? void 0 : t()), ot("resolve", s, e))
  };
}
const Tn = { class: "maz-flex maz-items-center maz-gap-2" }, zn = /* @__PURE__ */ be({
  __name: "MazDialogPromise",
  props: {
    data: { default: void 0 },
    message: { default: void 0 },
    identifier: { default: void 0 },
    buttons: { default: void 0 },
    modelValue: { type: Boolean },
    title: {},
    noClose: { type: Boolean },
    maxWidth: {},
    minWidth: {},
    scrollable: { type: Boolean },
    persistent: { type: Boolean },
    teleportSelector: {},
    beforeClose: {},
    noCloseOnEscKey: { type: Boolean },
    transitionName: {},
    backdropClass: {},
    backdropContentClass: {},
    contentPadding: { type: Boolean },
    justify: {},
    align: {},
    variant: {},
    cancelText: {},
    cancelButton: { type: [Boolean, Object] },
    confirmText: {},
    confirmButton: { type: [Boolean, Object] }
  },
  emits: ["open", "close"],
  setup(s, { expose: e }) {
    const t = s, n = H(() => import("./MazBtn-UGfo85SW.mjs")), { dialogState: o, rejectDialog: i, resolveDialog: r, data: a } = Dt(), l = h(() => {
      var m;
      return t.buttons ?? ((m = t.data) == null ? void 0 : m.buttons) ?? a.value.buttons;
    }), u = h(() => ({
      ...ie,
      ...a.value,
      ...t.data
    })), y = h(() => {
      var C, p, S, D;
      if (!(((C = a.value) == null ? void 0 : C.cancelButton) ?? ((p = t.data) == null ? void 0 : p.cancelButton) ?? ie.cancelButton))
        return;
      const d = {
        ...ie.cancelButton,
        ...(S = a.value) == null ? void 0 : S.cancelButton,
        ...(D = t.data) == null ? void 0 : D.cancelButton
      };
      return {
        ...d,
        text: t.cancelText || u.value.cancelText || d.text
      };
    }), T = h(() => {
      var C, p, S, D;
      if (!(((C = a.value) == null ? void 0 : C.confirmButton) ?? ((p = t.data) == null ? void 0 : p.confirmButton) ?? ie.confirmButton))
        return;
      const d = {
        ...ie.confirmButton,
        ...(S = a.value) == null ? void 0 : S.confirmButton,
        ...(D = t.data) == null ? void 0 : D.confirmButton
      };
      return {
        ...d,
        text: t.confirmText || u.value.confirmText || d.text
      };
    }), w = h(
      () => o.value.find(({ id: m }) => m === t.identifier)
    ), f = b();
    e({
      close: () => {
        var m, d;
        return (d = (m = f.value) == null ? void 0 : m.close) == null ? void 0 : d.call(m);
      }
    });
    function k(m) {
      return "type" in m && (m.type === "resolve" || m.type === "reject");
    }
    function z(m, d) {
      return k(d) ? d.type === "resolve" ? r(m, d.response) : i(m, d.response) : r(m, void 0, d.action);
    }
    return (m, d) => {
      var C;
      return A(), N(bn, te({
        ref_key: "dialog",
        ref: f
      }, { ...m.$attrs, ...t }, {
        "model-value": ((C = w.value) == null ? void 0 : C.isActive) ?? m.modelValue ?? !1,
        onClose: d[2] || (d[2] = (p) => m.$emit("close", p)),
        onOpen: d[3] || (d[3] = (p) => m.$emit("open", p)),
        "onUpdate:modelValue": d[4] || (d[4] = (p) => O(i)(w.value))
      }), {
        title: q(() => [
          R(m.$slots, "title", {}, () => {
            var p;
            return [
              J(
                X(m.title || ((p = u.value) == null ? void 0 : p.title)),
                1
                /* TEXT */
              )
            ];
          })
        ]),
        default: q(() => [
          R(m.$slots, "default", {
            resolve: (p) => O(r)(w.value, p),
            reject: (p) => O(i)(w.value, p)
          }, () => {
            var p;
            return [
              J(
                X(m.message || ((p = u.value) == null ? void 0 : p.message)),
                1
                /* TEXT */
              )
            ];
          })
        ]),
        footer: q(() => [
          R(m.$slots, "footer-button", {
            resolve: (p) => O(r)(w.value, p),
            reject: (p) => O(i)(w.value, p)
          }, () => [
            V("div", Tn, [
              l.value ? (A(!0), U(
                Ze,
                { key: 0 },
                Rt(l.value, (p, S) => (A(), N(O(n), te({
                  key: S,
                  ref_for: !0
                }, {
                  ...p,
                  type: "button"
                }, {
                  onClick: (D) => z(w.value, p)
                }), {
                  default: q(() => [
                    J(
                      X(p.text),
                      1
                      /* TEXT */
                    )
                  ]),
                  _: 2
                  /* DYNAMIC */
                }, 1040, ["onClick"]))),
                128
                /* KEYED_FRAGMENT */
              )) : (A(), U(
                Ze,
                { key: 1 },
                [
                  y.value ? (A(), N(
                    O(n),
                    te({ key: 0 }, y.value, {
                      onClick: d[0] || (d[0] = (p) => O(i)(w.value))
                    }),
                    {
                      default: q(() => [
                        R(m.$slots, "cancel-text", {}, () => [
                          J(
                            X(y.value.text),
                            1
                            /* TEXT */
                          )
                        ])
                      ]),
                      _: 3
                      /* FORWARDED */
                    },
                    16
                    /* FULL_PROPS */
                  )) : B("v-if", !0),
                  T.value ? (A(), N(
                    O(n),
                    te({ key: 1 }, T.value, {
                      onClick: d[1] || (d[1] = (p) => O(r)(w.value))
                    }),
                    {
                      default: q(() => [
                        R(m.$slots, "confirm-text", {}, () => [
                          J(
                            X(T.value.text),
                            1
                            /* TEXT */
                          )
                        ])
                      ]),
                      _: 3
                      /* FORWARDED */
                    },
                    16
                    /* FULL_PROPS */
                  )) : B("v-if", !0)
                ],
                64
                /* STABLE_FRAGMENT */
              ))
            ])
          ])
        ]),
        _: 3
        /* FORWARDED */
      }, 16, ["model-value"]);
    };
  }
}), it = {
  identifier: "main-dialog"
};
class Ln {
  constructor(e, t = it) {
    this.app = e, this.globalOptions = t;
  }
  open(e) {
    const t = {
      ...it,
      ...this.globalOptions,
      ...e
    }, { destroy: n, vNode: o } = Ke(zn, {
      props: t,
      app: this.app
    }), { showDialogAndWaitChoice: i } = Dt();
    function r() {
      var l, u, y;
      (u = (l = o.component) == null ? void 0 : l.exposed) == null || u.close(), (y = t.promiseCallback) == null || y.call(t), setTimeout(() => {
        n();
      }, 700);
    }
    return {
      promise: i(t.identifier, () => {
        r();
      }),
      destroy: n,
      close: r
    };
  }
}
const po = {
  install(s, e) {
    s.provide("dialog", new Ln(s, e));
  }
}, kn = { class: "m-toast__message-wrapper" }, Cn = { class: "m-toast__message" }, In = { class: "maz-flex maz-items-center maz-gap-2" }, An = { key: 0 }, Sn = {
  key: 4,
  class: "progress-bar maz-absolute maz-inset-x-0 maz-bottom-0 maz-h-1"
}, xn = /* @__PURE__ */ be({
  __name: "MazToast",
  props: {
    message: { default: void 0 },
    position: { default: "bottom-right" },
    maxToasts: { type: [Number, Boolean], default: !1 },
    timeout: { type: [Number, Boolean], default: 1e4 },
    queue: { type: Boolean },
    noPauseOnHover: { type: Boolean },
    type: { default: "info" },
    link: { default: void 0 },
    action: { default: void 0 },
    persistent: { type: Boolean },
    icon: { type: Boolean, default: !0 }
  },
  emits: ["close", "click", "open"],
  setup(s, { expose: e, emit: t }) {
    const n = s, o = t, i = H(() => import("./MazBtn-UGfo85SW.mjs")), r = H(() => import("./x-mark-CnZwVvA7.mjs")), a = H(
      () => import("./arrow-top-right-on-square-jPmYJ34F.mjs")
    ), l = H(
      () => import("./exclamation-triangle-Z6d0ygp8.mjs")
    ), u = H(
      () => import("./exclamation-circle-CUk0ZajS.mjs")
    ), y = H(
      () => import("./information-circle-C7e5b_nL.mjs")
    ), T = H(() => import("./check-circle-Bf7AGSQ-.mjs")), w = H(() => import("./link-Br1lfjTR.mjs")), f = b(), k = h(() => {
      if (n.icon)
        switch (n.type) {
          case "danger":
            return l;
          case "info":
            return y;
          case "success":
            return T;
          case "warning":
            return u;
          default:
            return;
        }
    }), z = h(() => n.position.includes("top") ? "top" : "bottom"), m = h(() => n.position.includes("left") ? "left" : n.position.includes("right") ? "right" : "center"), d = h(() => m.value !== "center" ? m.value === "right" ? "m-slide-right" : "m-slide-left" : z.value === "top" ? "m-slide-top" : "m-slide-bottom"), C = b(!1), p = b(!1), S = b(), D = `m-toast-container m-reset-css --${z.value} --${m.value}`, L = `.${D.replaceAll(" ", ".")}`, P = Is({
      callback: oe,
      timeout: typeof n.timeout == "number" ? n.timeout : 0,
      callbackOffsetTime: 200
    });
    function j() {
      const v = document.querySelector(L);
      if (!v && !v) {
        const x = document.body, M = document.createElement("div");
        M.className = D, x.append(M);
      }
    }
    function ne() {
      const v = document.querySelector(L);
      return !n.queue && n.maxToasts === !1 ? !1 : typeof n.maxToasts == "number" && v ? n.maxToasts <= v.childElementCount : v && v.childElementCount > 0;
    }
    function Q() {
      if (ne()) {
        S.value = setTimeout(Q, 250);
        return;
      }
      const v = document.querySelector(L);
      f.value && v && v.prepend(f.value), p.value = !0, typeof n.timeout == "number" && n.timeout > 0 && P.start();
    }
    const de = b("100%");
    function $e() {
      switch (n.type) {
        case "danger":
          return "maz-bg-danger-700";
        case "info":
          return "maz-bg-info-700";
        case "success":
          return "maz-bg-success-700";
        case "warning":
          return "maz-bg-warning-700";
        default:
          return "maz-bg-primary";
      }
    }
    G(
      P.remainingTime,
      (v) => {
        if (typeof n.timeout == "number") {
          const x = 100 * v / n.timeout;
          de.value = `${x}%`;
        }
      }
    );
    function me(v) {
      o("click", v), n.persistent || oe();
    }
    async function fe(v, x) {
      var M;
      C.value = !0, await v(), C.value = !1, (M = n.action) != null && M.closeToast && me(x);
    }
    function he(v) {
      n.noPauseOnHover || (v ? P.pause() : P.resume());
    }
    function Ve() {
      P.stop(), S.value && clearTimeout(S.value);
    }
    function oe() {
      Ve(), p.value = !1;
    }
    e({ closeToast: oe });
    function ke() {
      o("open");
    }
    function Ce() {
      var x;
      o("close"), (x = f.value) == null || x.remove();
      const v = document.querySelector(L);
      v && !(v != null && v.hasChildNodes()) && v.remove();
    }
    return ce(() => {
      j(), Q();
    }), (v, x) => (A(), N(dt, {
      name: d.value,
      onAfterLeave: Ce,
      onAfterEnter: ke,
      persisted: ""
    }, {
      default: q(() => [
        ut(V(
          "button",
          {
            ref_key: "Toaster",
            ref: f,
            class: ue(["m-toast m-reset-css", [
              `--${v.type}`,
              `--${z.value}`,
              `--${m.value}`,
              { "maz-pb-1": typeof v.timeout == "number" && v.timeout > 0, "--persistent": v.persistent }
            ]]),
            role: "alert",
            onMouseover: x[2] || (x[2] = (M) => he(!0)),
            onMouseleave: x[3] || (x[3] = (M) => he(!1)),
            onClick: x[4] || (x[4] = Z((M) => {
              var ve;
              return v.link && !((ve = v.link) != null && ve.closeToast) ? void 0 : me(M);
            }, ["stop"]))
          },
          [
            k.value ? (A(), N(_t(k.value), {
              key: 0,
              class: "maz-text-2xl"
            })) : B("v-if", !0),
            V("div", kn, [
              V(
                "p",
                Cn,
                X(v.message),
                1
                /* TEXT */
              )
            ]),
            v.action ? (A(), N(O(i), {
              key: 1,
              "data-test": "action-btn",
              color: v.type,
              pastel: "",
              loading: C.value,
              size: "sm",
              onClick: x[0] || (x[0] = Z((M) => v.action ? fe(v.action.func, M) : void 0, ["stop"]))
            }, {
              default: q(() => [
                J(
                  X(v.action.text),
                  1
                  /* TEXT */
                )
              ]),
              _: 1
              /* STABLE */
            }, 8, ["color", "loading"])) : B("v-if", !0),
            v.link ? (A(), N(O(i), {
              key: 2,
              "data-test": "link-btn",
              color: v.type,
              pastel: "",
              size: "xs",
              href: v.link.href,
              target: v.link.target ?? "_self"
            }, {
              default: q(() => {
                var M;
                return [
                  V("div", In, [
                    v.link.text ? (A(), U(
                      "span",
                      An,
                      X(v.link.text),
                      1
                      /* TEXT */
                    )) : B("v-if", !0),
                    ((M = v.link) == null ? void 0 : M.target) === "_blank" ? (A(), N(O(a), {
                      key: 1,
                      class: "maz-text-xl"
                    })) : (A(), N(O(w), {
                      key: 2,
                      class: "maz-text-xl"
                    }))
                  ])
                ];
              }),
              _: 1
              /* STABLE */
            }, 8, ["color", "href", "target"])) : B("v-if", !0),
            v.persistent ? B("v-if", !0) : (A(), U("button", {
              key: 3,
              class: "--close",
              onClick: x[1] || (x[1] = Z((M) => me(M), ["stop"]))
            }, [
              ee(O(r), { class: "--icon maz-text-xl" })
            ])),
            typeof v.timeout == "number" && v.timeout > 0 ? (A(), U("div", Sn, [
              V(
                "div",
                {
                  style: Kt({
                    width: de.value
                  }),
                  class: ue(["maz-h-full !maz-transition-all !maz-duration-200 !maz-ease-linear", $e()])
                },
                null,
                6
                /* CLASS, STYLE */
              )
            ])) : B("v-if", !0)
          ],
          34
          /* CLASS, NEED_HYDRATION */
        ), [
          [ct, p.value]
        ])
      ]),
      _: 1
      /* STABLE */
    }, 8, ["name"]));
  }
}), On = /* @__PURE__ */ Me(xn, [["__scopeId", "data-v-b70bc12e"]]), Dn = {
  position: "bottom-right",
  timeout: 1e4,
  persistent: !1,
  icon: !0
};
class Pn {
  constructor(e, t) {
    this.app = e, this.globalOptions = t;
  }
  show(e, t) {
    const n = {
      ...Dn,
      ...this.globalOptions,
      ...t,
      message: e
    }, { destroy: o, vNode: i } = Ke(On, {
      props: n,
      app: this.app
    });
    return {
      destroy: o,
      close: () => {
        var r, a;
        return (a = (r = i.component) == null ? void 0 : r.exposed) == null ? void 0 : a.closeToast();
      }
    };
  }
  getLocalOptions(e, t) {
    return {
      type: e,
      ...t
    };
  }
  message(e, t) {
    return this.show(e, this.getLocalOptions("theme", t));
  }
  success(e, t) {
    return this.show(e, this.getLocalOptions("success", t));
  }
  error(e, t) {
    return this.show(e, this.getLocalOptions("danger", t));
  }
  info(e, t) {
    return this.show(e, this.getLocalOptions("info", t));
  }
  warning(e, t) {
    return this.show(e, this.getLocalOptions("warning", t));
  }
}
function Mn(s, e) {
  return new Pn(s, e);
}
const go = {
  install(s, e) {
    s.provide("toast", Mn(s, e));
  }
}, ae = "";
function $n(s) {
  return s.filter((e, t, n) => t === n.indexOf(e));
}
function Vn(s) {
  return (e = ae) => typeof e == "function" ? s.findIndex((...t) => e(...t)) > -1 : s.includes(e);
}
const Hn = (s) => s.length > 0;
function Bn(s) {
  return (e = ae) => $n([...s, e]);
}
function Nn(s) {
  return (e = ae) => s.filter((t) => t !== e);
}
class Fn {
  constructor() {
    c(this, "_loaders", b([]));
  }
  get loaders() {
    return h(() => this._loaders.value);
  }
  stop(e = ae) {
    this._loaders.value = Nn(this._loaders.value)(e);
  }
  start(e = ae) {
    return this._loaders.value = Bn(this._loaders.value)(e), () => this.stop(e);
  }
  get anyLoading() {
    return h(() => Hn(this._loaders.value));
  }
  isLoading(e = ae) {
    return h(() => Vn(this._loaders.value)(e)).value;
  }
}
const jn = new Fn(), yo = {
  install: (s) => {
    s.provide("wait", jn);
  }
};
export {
  vo as $,
  Vs as A,
  _s as B,
  Rs as C,
  Ys as D,
  co as E,
  Gs as F,
  Qs as G,
  fo as H,
  en as I,
  on as J,
  an as K,
  un as L,
  mt as M,
  ro as N,
  ao as O,
  vs as P,
  F as Q,
  Ke as R,
  Je as S,
  lo as T,
  Bs as U,
  Ls as V,
  uo as W,
  Hs as X,
  As as Y,
  dn as Z,
  Me as _,
  Rn as a,
  ho as a0,
  Ln as a1,
  po as a2,
  go as a3,
  Pn as a4,
  yo as a5,
  Fn as a6,
  jn as a7,
  _n as b,
  Kn as c,
  Yn as d,
  Zn as e,
  Xn as f,
  gs as g,
  Gn as h,
  Pe as i,
  Qn as j,
  Jn as k,
  ys as l,
  eo as m,
  to as n,
  Is as o,
  so as p,
  no as q,
  oo as r,
  Zt as s,
  mo as t,
  Wn as u,
  xs as v,
  Os as w,
  Ms as x,
  $s as y,
  io as z
};
